import { createReducer, on } from "@ngrx/store";
import { SpotlightResponse, SurveyWordPair } from "../models/spotlight-response.interface";
import * as surveyActions from '../actions/survey.actions';
import { GetQuestionnaireResponse } from "../services/questionnaire-api.service";

export const surveyFeatureKey = 'survey';

export interface State {
  uniqueRef: string;
  spotlightResponseId: number;
  currentPage: number;
  currentPageIsLastPage: boolean;
  isLoading: boolean;
  loadingError: any;
  isSaving: boolean;
  savingError: any;
  hasInProgress: boolean;
  survey: SpotlightResponse | undefined;
  wordPairs: SurveyWordPair[];
  associatedQuestionnaires: GetQuestionnaireResponse[];
  languageCode: string | undefined;
}

export const initialState: State = {
  uniqueRef: '',
  spotlightResponseId: 0,
  currentPage: 0,
  currentPageIsLastPage: false,
  isLoading: false,
  loadingError: undefined,
  isSaving: false,
  savingError: undefined,
  hasInProgress: false,
  survey: undefined,
  wordPairs: [],
  associatedQuestionnaires: [],
  languageCode: undefined
}

export const reducer = createReducer(
  initialState,
  on(surveyActions.LoadSurveyFromInvite.success, (state, props) => ({
    ...state,
    survey: {
      ...props.data,
      id: state.spotlightResponseId,
      uniqueRef: state.uniqueRef
    },
    currentPageIsLastPage: state.currentPage == (props.data?.pages?.length ?? 0) - 1
  })),
  on(surveyActions.LoadSurveyFromSpotlightGroup.success, (state, props) => ({
    ...state,
    survey: props.data,
    currentPageIsLastPage: state.currentPage == (props.data?.pages?.length ?? 0) - 1
  })),
  on(surveyActions.LoadSurveyInProgress.success, (state, props) => {
    //If there was no survey in progress then data will be null
    if (!props.data)
      return state;

    //If we got data then modify the state like usual
    return ({
      ...state,
      survey: props.data,
      spotlightResponseId: props.data?.id ?? 0,
      uniqueRef: props.data?.uniqueRef ?? '',
      hasInProgress: true,
      currentPageIsLastPage: state.currentPage == (props.data?.pages?.length ?? 0) - 1
    });
  }),
  on(surveyActions.SaveSurveyAnswers.request, (state, props) => ({
    ...state,
    isSaving: true,
    survey: state.survey ? {
      ...state.survey,
      answers: props.request.answers ?? ''
    } : undefined
  })),
  on(surveyActions.SaveSurveyAnswers.success, (state, props) => ({
    ...state,
    isSaving: false,
    currentPage: state.survey?.pages && state.currentPage < state.survey?.pages?.length ? state.currentPage + 1 : state.currentPage,
    currentPageIsLastPage: (state.survey?.pages && state.currentPage < state.survey?.pages?.length ? state.currentPage + 1 : state.currentPage) == (state.survey?.pages?.length ?? 0) 
  })),
  on(surveyActions.SaveSurveyAnswers.failure, (state, props) => ({
    ...state,
    isSaving: false,
  })),
  on(surveyActions.GetLinkedQuestionnaires.success, (state, props) => ({
    ...state,
    associatedQuestionnaires: props.data
  })),
  on(surveyActions.GetLinkedQuestionnaires.failure, (state, props) => ({
    ...state,
    associatedQuestionnaires: []
  })),
  on(surveyActions.setSurveyPage, (state, props) => ({
    ...state,
    currentPage: props.pageId,
    currentPageIsLastPage: props.pageId == (state.survey?.pages?.length ?? 0) - 1
  })),
  on(surveyActions.previousPage, (state) => ({
    ...state,
    currentPage: state.currentPage != -1 ? state.currentPage - 1 : state.currentPage,
    currentPageIsLastPage: false
  })),
  on(surveyActions.nextPage, (state) => ({
    ...state,
    currentPage: state.survey?.pages && state.currentPage < state.survey?.pages?.length ? state.currentPage + 1 : state.currentPage,
    currentPageIsLastPage: (state.survey?.pages && state.currentPage < state.survey?.pages?.length ? state.currentPage + 1 : state.currentPage) == (state.survey?.pages?.length ?? 0) - 1
  })),
  on(surveyActions.setSpotlightResponseId, (state, props) => ({
    ...state,
    spotlightResponseId: props.spotlightResponseId
  })),
  on(surveyActions.setUniqueRef, (state, props) => ({
    ...state,
    uniqueRef: props.uniqueRef
  })),
  on(surveyActions.setLanguageCode, (state, props) => ({
    ...state,
    languageCode: props.languageCode
  }))
);
