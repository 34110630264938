import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
} from '@angular/animations';


// Basic

export const fader =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({
          //position: 'absolute',
          //left: 0,
          //width: '100%',
          opacity: 0,
          //transform: 'scale(0) translateY(100%)',
        }),
      ], { optional: true }),
      query(':enter', [
        animate('600ms ease', style({
          opacity: 1,
          //transform: 'scale(1) translateY(0)'
        })),
      ], { optional: true })
    ]),
  ]);


//export const slideInAnimation =
//  trigger('routeAnimations', [
//    transition('WelcomePage <=> SignUpPage', [
//      style({ position: 'relative' }),
//      query(':enter, :leave', [
//        style({
//          position: 'absolute',
//          top: 0,
//          left: 0,
//          width: '100%'
//        })
//      ], { optional: true }),
//      query(':enter', [
//        style({ left: '-100%' })
//      ], { optional: true }),
//      query(':leave', animateChild(), { optional: true }),
//      group([
//        query(':leave', [
//          animate('300ms ease-out', style({ left: '100%' }))
//        ], { optional: true }),
//        query(':enter', [
//          animate('300ms ease-out', style({ left: '0%' }))
//        ], { optional: true })
//      ]),
//      query(':enter', animateChild(), { optional: true }),
//    ]),
    //transition('* <=> QuestionPage', [
    //  style({ position: 'relative' }),
    //  query(':enter, :leave', [
    //    style({
    //      position: 'absolute',
    //      top: 0,
    //      left: '100%',
    //      width: '100%'
    //    })
    //  ], { optional: true }),
    //  query(':enter', [
    //    style({ left: '100%' })
    //  ], { optional: true }),
    //  query(':leave', animateChild(), { optional: true }),
    //  group([
    //    query(':leave', [
    //      animate('200ms ease-out', style({ left: '-100%' }))
    //    ], { optional: true }),
    //    query(':enter', [
    //      animate('300ms ease-out', style({ left: '0%' }))
    //    ], { optional: true })
    //  ]),
    //  query(':enter', animateChild(), { optional: true }),
    //])
  //]);
