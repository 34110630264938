import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, take, takeUntil } from 'rxjs';
import { setTitleBar } from '../actions/layout.actions';
import { GetCoachingSessionInfo, GetFeedbackOptions, SaveCoachingSessionFeedback, SetShowGroupFeedbackOptions, SetShowPositiveFeedbackOptions } from '../actions/questionnaire.actions';
import { CoachingSessionFeedback } from '../models/coaching-session-feedback.interface';
import { AppState } from '../reducers';
import { selectCoachingSessionInfo, selectCoachingSessionSaved, selectCoachingSessionSaving, selectFeedbackOptions, selectShowPositiveFeedbackOptions } from '../selectors/questionnaire.selectors';

@Component({
  selector: 'app-coaching-session-feedback',
  templateUrl: './coaching-session-feedback.component.html',
  styleUrls: ['./coaching-session-feedback.component.scss']
})
export class CoachingSessionFeedbackComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<boolean>();
  feedbackOptions$ = this.store.select(selectFeedbackOptions);
  isPositive$ = this.store.select(selectShowPositiveFeedbackOptions);
  coachingInfo$ = this.store.select(selectCoachingSessionInfo);

  isSaving$ = this.store.select(selectCoachingSessionSaving);
  isSaved$ = this.store.select(selectCoachingSessionSaved);

  tentativeRating = 0;
  rating = 0;
  hovering = false;

  showFeedbackOptions = false;

  selectedFeedback: number[] = [];

  additionalComments = '';

  sessionId: number = 0;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.store.dispatch(setTitleBar({ visible: false }));
    this.store.dispatch(GetFeedbackOptions.Request());

    this.route.paramMap
      .pipe(take(1))
      .subscribe(params => {
        this.sessionId = Number(params.get('sessionId'));
        this.store.dispatch(GetCoachingSessionInfo.Request({ sessionId: this.sessionId }));
      });

    this.coachingInfo$.pipe(takeUntil(this.destroyed$)).subscribe(coachingInfo => {
      if (coachingInfo) {
        this.store.dispatch(SetShowGroupFeedbackOptions({ isGroup: !coachingInfo.isIndividualSession }));
      }
    });
  }

  onRatingClick(rating: number) {
    // In the event that the user decides to change their rating after already selecting some feedback options
    // Clear down the selection only if the options change
    if ((rating === 5 && this.rating < 5) || (rating < 5 && this.rating === 5)) {
      this.selectedFeedback = [];
    }

    this.rating = rating;
    this.showFeedbackOptions = true;

    if (rating >= 5) {
      this.store.dispatch(SetShowPositiveFeedbackOptions({ positive: true }));
    } else {
      this.store.dispatch(SetShowPositiveFeedbackOptions({ positive: false }));
    }
  }

  onFeedbackClick(id: number) {
    if (this.selectedFeedback.includes(id)) {
      this.selectedFeedback = this.selectedFeedback.filter(x => x != id);
    } else {
      this.selectedFeedback.push(id);
    }
  }

  submitStarRating() {
    const sessionFeedback: CoachingSessionFeedback = {
      id: this.sessionId,
      feedbackIds: this.selectedFeedback,
      additionalComments: this.additionalComments,
      starRating: this.rating,
    };

    this.store.dispatch(SaveCoachingSessionFeedback.Request({ sessionFeedback }));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
