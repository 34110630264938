import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-common-types';

export const faCustArrowUp: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'cust-arrow-up' as IconName,
  icon: [
    15.27,
    15.27,
    [],
    'e001',
    'M15.25 15.26L7.64 0 0 15.26l.2-.05 7.29-3.13a.47.47 0 01.4 0l5.86 2.52z',
  ],
};
export const faCustArrowDown: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'cust-arrow_down' as IconName,
  icon: [
    15.27,
    15.27,
    [],
    'e002',
    'M.03.01l1.53.66 5.86 2.52a.47.47 0 00.4 0L15.11.06h.15l-7.63 15.2L0 .03z',
  ],
};

export const faNextSVG: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'next-svg' as IconName,
  icon: [
    20,
    20,
    [],
    'e003',
    'M 7.9492187,4.5605469 7.03125,5.4804687 11.550781,10 7.03125,14.519531 7.9492187,15.439453 13.388672,10 Z',
  ],
};

export const faBackSVG: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'back-svg' as IconName,
  icon: [
    20,
    20,
    [],
    'e004',
    'M 12,4.5605469 6.5605469,10 12,15.439453 12.919922,14.519531 8.3984375,10 12.919922,5.4804687 Z',
  ],
};

export const faDropdownSVG: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'dropdown-svg' as IconName,
  icon: [
    20,
    20,
    [],
    'e006',
    'M 5.4609375,7.0507812 4.5390625,7.96875 9.96875,13.410156 15.410156,7.96875 14.490234,7.0507812 9.9707031,11.570313 Z',
  ],
};
