<div class="sign-up overflow-auto p-0 m-0 d-flex d-row">
  <div class="bg-left"></div>
  <div class="bg-right"></div>
  <img class="bg-logo" src="./assets/svg/Mindflick_Monogram_Colour_RGB.svg" />
  <img class="bg-pattern"
       src="./assets/svg/nudge-patterns/pages-bg-pattern.svg" />
  <div class="d-flex flex-column left__container m-0">
    <img class="logo" src="./assets/images/logos/mindflick.svg" />
    <div class="d-flex flex-column container__greeting">
      <h1 class="text-white pt-4 pb-0 mb-0 align-middle greeting">WELCOME</h1>
      <h1 class="text-white greeting">TO SPOTLIGHT</h1>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center right__container mr-5">
    <h2 class="text-white">Enter your details</h2>
    <div class="d-flex flex-column">
      <form [formGroup]="signUpForm" class="row g-3 gx-4 mt-35 py-0">
        <div class="form-group col-sm-12 col-md-6">
          <label for="firstName" class="form-label form-label-light">First name*</label>
          <input formControlName="firstName"
                 type="text"
                 class="form-control form-control-light"
                 id="firstName"
                 name="firstName"
                 placeholder="Required" />
          <!-- below triggers if lets begin is clicked and the below is an error -->
          <div *ngIf="
              signUpForm.controls.firstName.errors &&
              signUpForm.controls.firstName.invalid &&
              (signUpForm.controls.firstName.dirty ||
                signUpForm.controls.firstName.touched)
            "
               class="form-error">
            <div *ngIf="signUpForm.controls.firstName.errors?.required">
              * Please enter your first name
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-6 pad-left">
          <label for="lastName" class="form-label form-label-light">Last name*</label>
          <input formControlName="lastName"
                 type="text"
                 class="form-control form-control-light"
                 id="lastName"
                 name="lastName"
                 placeholder="Required" />
          <div *ngIf="
              signUpForm.controls.lastName.errors &&
              signUpForm.controls.lastName.invalid &&
              (signUpForm.controls.lastName.dirty ||
                signUpForm.controls.lastName.touched)
            "
               class="form-error">
            <div *ngIf="signUpForm.controls.lastName.errors?.required">
              * Please enter your last name
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-md-6">
          <label for="role" class="form-label form-label-light">Email*</label>
          <input formControlName="email"
                 type="text"
                 class="form-control form-control-light"
                 id="email"
                 name="email"
                 placeholder="Email" />
          <div *ngIf="
              signUpForm.controls.email.errors &&
              signUpForm.controls.email.invalid &&
              (signUpForm.controls.email.dirty ||
                signUpForm.controls.email.touched)
            "
               class="form-error">
            <div *ngIf="signUpForm.controls.email.errors?.required">
              * Please enter your email
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-md-6">
          <label for="companyName" class="form-label form-label-light">Company name</label>
          <input formControlName="companyName"
                 type="text"
                 class="form-control form-control-light"
                 id="companyName"
                 name="companyName"
                 placeholder="Company name (optional)" />
        </div>

        <div class="form-group col-sm-12 col-md-6 age">
          <label for="age" class="form-label form-label-light">Age</label>
          <input formControlName="age"
                 type="number"
                 class="form-control form-control-light"
                 id="age"
                 name="age"
                 min="18"
                 max="120"
                 placeholder="Age (optional)" />
          <div *ngIf="
              signUpForm.controls.age.errors &&
              signUpForm.controls.age.invalid &&
              (signUpForm.controls.age.dirty || signUpForm.controls.age.touched)
            "
               class="form-error">
            <div *ngIf="signUpForm.controls.age.errors?.min">
              Age should be at least 18
            </div>

            <div *ngIf="signUpForm.controls.age.errors?.max">
              Age should be less than 120
            </div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-md-6" id="gender">
          <label for="gender" class="form-label form-label-light">Gender*</label>
          <app-dropdown-select formControlName="gender"
                               [label]="'Gender'"
                               [light]="true"
                               [placeholder]="'Please select'"
                               [options]="[
              { value: 'f', description: 'Woman' },
              { value: 'm', description: 'Man' },
              { value: 'n', description: 'Non-Binary' },
              { value: 's', description: 'Other' },
              { value: '?', description: 'Prefer Not To Say' }
            ]"
                               (click)="setGenderTouched()"></app-dropdown-select>

          <div *ngIf="
              signUpForm.controls.gender.errors &&
              signUpForm.controls.gender.invalid &&
              (signUpForm.controls.gender.dirty ||
                signUpForm.controls.gender.touched)
            "
               class="form-error">
            <div *ngIf="signUpForm.controls.gender.errors?.required">
              * Please select an option
            </div>
          </div>
        </div>

        <div class="mb-0">
          <div *ngIf="this.signUpForm.controls.gender.value == 's'"
               class="form-group col-12 gender-specify">
            <label class="form-label form-label-light">Specify gender</label>
            <input type="text"
                   class="form-control form-control-light"
                   placeholder="Specify gender (optional)" />
          </div>
        </div>

        <div class="form-group col-12 mt-35">
          <button id="begin"
                  type="button"
                  (click)="onSubmit()"
                  class="btn btn-purple btn-block w-100 btn-dark">
            Save and continue
          </button>
        </div>

        <div class="small-text text-grey agreement mt-15">
          By selecting ‘Save and continue’ you confirm that you are over 18
          years of age and have read and agreed to the
          <a href="https://app.mindflick.co/#/public/spotlight/privacy-policy"
             target="_blank">Spotlight Privacy Policy</a>, the
          <a href="https://app.mindflick.co/#/public/spotlght/acceptable-use"
             target="_blank">Acceptable Use Policy</a>
          and the
          <a href="https://app.mindflick.co/#/public/terms" target="_blank">Terms of use</a>.
        </div>
      </form>
    </div>
  </div>
</div>
