export interface SelectValue {
  value: any;
  description: string;
}

export enum QuestionnaireQuestionTypes {
  Strengthometer,
  TextSingleLine,
  TextMultipleLines,
  LikertScale,
  RateOutOf10,
  DoubleStrengthometer,
  YesNo,
  CustomLikertScale,
  SingleChoice,
  MultiChoice,
  Sweetspot,
  Email,
}
