import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromQuestionnaire from './questionnaire.reducer';
import * as fromLayout from './layout.reducer';
import * as fromSurvey from './survey.reducer';


export interface AppState {

  [fromQuestionnaire.questionnaireFeatureKey]: fromQuestionnaire.State;
  [fromLayout.layoutFeatureKey]: fromLayout.State;
  [fromSurvey.surveyFeatureKey]: fromSurvey.State;
}

export const reducers: ActionReducerMap<AppState> = {

  [fromQuestionnaire.questionnaireFeatureKey]: fromQuestionnaire.reducer,
  [fromLayout.layoutFeatureKey]: fromLayout.reducer,
  [fromSurvey.surveyFeatureKey]: fromSurvey.reducer,
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
