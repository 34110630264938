import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as layoutActions from '../actions/layout.actions';
import { AppState } from '../reducers';
import { BackgroundStyle } from '../reducers/layout.reducer';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  @Output()
  goToSurveyClicked = new EventEmitter<void>();

  @Input()
  userFirstName: string | undefined;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.store.dispatch(layoutActions.setTitleBar({ visible: true }));
  }
}
