import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ChangeContext, Options } from '@confusedfish/ngx-slider-v2';
import { combineLatest, Subscription } from 'rxjs';
import { setTitleBar } from '../actions/layout.actions';
import {
  loadQuestionnaire,
  nextQuestionnairePage,
  saveAnswers,
  setQuestionnairePage,
} from '../actions/questionnaire.actions';
import { AppState } from '../reducers';
import {
  currentPage,
  currentPageId,
  currentPageQuestions,
  onIntroPage,
  onLastPage,
  onLastQuestionPage,
  pageCount,
  selectIsLoadingQuestionnaire,
  selectIsSavingQuestionnaire,
  selectLoadingQuestionnaireError,
  selectQuestionnaire,
} from '../selectors/questionnaire.selectors';
import {
  QuestionnaireAnswer,
  QuestionnaireAPIService,
  QuestionnaireQuestion,
} from '../services/questionnaire-api.service';
import { faBackSVG, faNextSVG } from '../icons';

export enum QuestionnaireQuestionTypes {
  Strengthometer, 
  TextSingleLine,
  TextMultipleLines,
  LikertScale,
  RateOutOf10,
  DoubleStrengthometer,
  YesNo,
  CustomLikertScale,
  SingleChoice,
  MultiChoice,
  Sweetspot,
  Email
}

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  emailControl = new FormControl(null, Validators.email);

  QuestionType = QuestionnaireQuestionTypes;

  constructor(
    public questionnaireService: QuestionnaireAPIService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (window.innerHeight < 900) {
      this.store.dispatch(setTitleBar({ visible: false }));
    } else {
      this.store.dispatch(setTitleBar({ visible: true }));
    }

    this.subs.add(
      this.route.paramMap.subscribe((params) => {
        let page = params.get('page') == null ? -1 : Number(params.get('page'));
        let uniqueRef = params.get('uniqueRef') ?? 'undefined';
        let questionnaireResponseId =
          Number(params.get('questionnaireResponseId')) ?? 0;

        //Pipe the URL parameters into NGRX which will trigger the load of the questionnaire questions
        this.store.dispatch(
          loadQuestionnaire({ uniqueRef, questionnaireResponseId })
        );

        if (
          uniqueRef != 'undefined' &&
          !window.location.href.includes('feedback')
        ) {
          this.store.dispatch(setQuestionnairePage({ pageId: page }));
        } else {
          this.store.dispatch(setQuestionnairePage({ pageId: 0 }));
        }
      })
    );

    this.subs.add(
      this.pageId$.subscribe((page) => {
        this.currentPageId = page;
        if (page === -1) {
          this.store.dispatch(setTitleBar({ visible: true }));
        }
      })
    );
    this.subs.add(
      this.onLastQuestionPage$.subscribe(
        (last) => (this.isLastQuestionPage = last)
      )
    );

    this.subs.add(
      combineLatest([this.currentPageQuestions$, this.pageCount$]).subscribe(
        ([questions, pageCount]) => {
          //This will be called whenever the page changes
          //Copy the previous answers into a two way binadable array
          if (questions) {
            this.questions = questions.map((q) => {
              q.likertOptionsArray = q.likertOptions
                ? q.likertOptions?.split(',')
                : [];
              return q;
            });
            this.answers = questions.map((q) => {
              if (q.isTextType) {
                return q.answerTextValue;
              } else {
                if (
                  q.answerValue === undefined &&
                  (q.questionType == 0 ||
                    q.questionType == 5 ||
                    q.questionType == 10)
                )
                  return 50;
                return q.answerValue;
              }
            });

            this.ngxSliderOptions = questions.map(
              (q) =>
                ({
                  floor: 0,
                  ceil: 100,
                  showTicks: q.questionType == 5 || q.questionType == 10,
                  hidePointerLabels: true,
                  hideLimitLabels: true,
                  ticksArray:
                    q.questionType != 5
                      ? q.questionType != 10
                        ? []
                        : [40, 61]
                      : [q.previousValue === undefined ? 50 : q.previousValue],
                  //selectionBarGradient: q.questionType != 10 ? undefined : { from: '#ff8093', to: '#78c0d3' },
                  //selectionBarGradient: q.questionType != 10 ? undefined : { from: '#d3d3d3', to: '#d3d3d3' },
                  showSelectionBarFromValue:
                    q.questionType != 10 ? undefined : 50,
                  showSelectionBar: q.questionType != 10 ? false : true,
                  getSelectionBarColor: (value: number): string => {
                    if (q.questionType != 10) return '#d3d3d3';

                    let color = 'yellow';

                    if (value < 39 || value >= 61) {
                      color = 'red';
                    }
                    return color;
                  },
                } as Options)
            );

            this.stacked = [];
            var stackedValue = 100 / pageCount - 0.1;
            for (let i = 0; i < this.currentPageId + 1; i++) {
              var whiteSpace = 0.1;
              if (i === pageCount - 1) {
                stackedValue += 0.1;
                whiteSpace = 0;
              }
              this.stacked.push({
                value: stackedValue,
                type: 'warning',
              });
              this.stacked.push({
                value: whiteSpace,
                type: 'white',
              });
            }
          } else {
            this.store.dispatch(setQuestionnairePage({ pageId: 0 }));
          }
        }
      )
    );
  }

  private _questionsContainer!: ElementRef;

  @ViewChild('questionsContainer', { static: false })
  set questionsContainer(questionTemplate: ElementRef) {
    if (!questionTemplate) return;
    questionTemplate.nativeElement.scrollTop = 0;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    this._questionsContainer = questionTemplate;
  }

  get questionsContainer() {
    return this._questionsContainer;
  }

  isLastQuestionPage: boolean = false;
  questions: QuestionnaireQuestion[] = [];
  answers: any[] = [];
  ngxSliderOptions: Options[] = [];
  currentPageId: number = -1;
  stacked: any[] = [];
  requiredError: boolean = false;

  likertOptions = [
    'Strongly Disagree',
    'Disagree',
    'Neutral',
    'Agree',
    'Strongly Agree',
  ];

  outOfTenOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  pageId$ = this.store.select(currentPageId);
  currentPage$ = this.store.select(currentPage);
  currentPageQuestions$ = this.store.select(currentPageQuestions);
  questionnaire$ = this.store.select(selectQuestionnaire);
  onLastPage$ = this.store.select(onLastPage);
  onLastQuestionPage$ = this.store.select(onLastQuestionPage);
  onIntroPage$ = this.store.select(onIntroPage);
  isSaving$ = this.store.select(selectIsSavingQuestionnaire);
  isLoading$ = this.store.select(selectIsLoadingQuestionnaire);
  loadingError$ = this.store.select(selectLoadingQuestionnaireError);
  pageCount$ = this.store.select(pageCount);

  next = faNextSVG;
  back = faBackSVG;

  subs = new Subscription();
  ngOnDestroy(): void {
    //destroy all the subscriptions at once
    if (this.subs != null) {
      this.subs.unsubscribe();
    }
  }

  public updateSliderOptions(
    question: QuestionnaireQuestion,
    index: number,
    changeContext: ChangeContext
  ) {
    if (question.questionType != 10) return;

    let value = changeContext.value;
    let options = this.ngxSliderOptions[index];
    if (value > 50) {
      if (options.showSelectionBarEnd) {
        options = {
          ...options,
          showSelectionBarEnd: false,
        };
        this.ngxSliderOptions[index] = options;
      }
    }
    if (value <= 50) {
      if (!options.showSelectionBarEnd) {
        options = {
          ...options,
          showSelectionBarEnd: true,
        };
        this.ngxSliderOptions[index] = options;
      }
    }
  }

  public updateMultiSelectAnswer(index: number, value: string) {
    if (this.answers[index]) {
      if (this.answers[index].some((x: string) => x == value)) {
        this.answers[index] = this.answers[index].filter(
          (x: string) => x != value
        );
      } else {
        this.answers[index] = [...this.answers[index], value];
      }
    } else {
      this.answers[index] = [value];
    }
    //if (event.currentTarget.checked) {
    //  if (this.answers[index])
    //    this.answers[index] = [...this.answers[index], value];
    //  else

    //} else {
    //  this.answers[index] = this.answers[index].filter((x: string) => x != value);
    //}
  }

  public multiSelectIsChecked(index: number, value: string) {
    if (!this.answers[index]) return false;

    return this.answers[index].some((x: string) => x == value);
  }

  previousPage() {
    if (this.currentPageId > 0)
      this.store.dispatch(setQuestionnairePage({ pageId: this.currentPageId - 1 }));
  }

  public nextPage(): void {
    var answersRequired = this.questions.some((x, i) => {
      return x.required && !this.answers[i];
    });

    if (answersRequired) {
      this.requiredError = true;
      this.scrollToQuestion(this.answers.findIndex((x) => !x));
      return;
    }

    this.requiredError = false;

    //Save the current page's answers
    if (this.currentPageId >= 0)
      this.store.dispatch(
        saveAnswers({
          nextPageOnceSaved: true,
          markComplete: this.isLastQuestionPage,
          answers: this.questions.map((question, index) => {
            var multiChoiceQuestion = question.questionType == 9;
            var multiChoiceAnswer = '';
            if (multiChoiceQuestion)
              multiChoiceAnswer = this.answers[index].join(',');

            return {
              questionnaireQuestionId: question.id,
              value:
                !question.isTextType && !multiChoiceQuestion
                  ? +this.answers[index]
                  : undefined,
              textValue: multiChoiceQuestion
                ? multiChoiceAnswer
                : question.isTextType
                ? this.answers[index]
                : undefined,
            } as QuestionnaireAnswer;
          }),
        })
      );
    else {
      this.store.dispatch(nextQuestionnairePage());
    }

    if (this.questionsContainer && this.questionsContainer.nativeElement)
      this.questionsContainer.nativeElement.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      });

    this.emailControl.reset();
  }

  public scrollToQuestion(questionId: number): void {
    let questionElement = document.getElementById('question_' + questionId);
    if (!questionElement) return;

    let top =
      questionElement.offsetTop -
      this.questionsContainer.nativeElement.offsetTop;

    this.questionsContainer.nativeElement.scroll({
      top: top,
      left: 0,
      behavior: 'smooth',
    });
  }

  public focusNextQuestion(currentQuestionId: number): void {
    //Basically we want the recently clicked blob to be at the top of the screen, just, thus maximising the chances of showing the next question detail while retaining their answer.

    //Scrolling to the next question is a bit involved due to the position:fixed elements
    //First find the element we want to scroll to
    let questionElement = document.getElementById(
      'question_' + currentQuestionId
    );
    if (!questionElement) return;

    //Put the top of that question at the top of the screen
    let top =
      questionElement.offsetTop -
      this.questionsContainer.nativeElement.offsetTop;

    //Only scroll down, not up
    if (this.questionsContainer.nativeElement.scrollTop < top)
      this.questionsContainer.nativeElement.scroll({
        top: top,
        left: 0,
        behavior: 'smooth',
      });
  }
}
