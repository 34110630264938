import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorText'
})
export class ErrorTextPipe implements PipeTransform {
  transform(error: any): string {

    if (!error)
      return '';

    if (error.error && error.error.exceptionMessage)
      return error.error.exceptionMessage;

    if (error.exceptionMessage)
      return error.exceptionMessage;

    if (error.message)
      return error.message;

    return JSON.stringify(error);
  }


}
