<div class="background h-100 d-flex flex-column align-items-center justify-content-center">
  <div class="rating-content d-flex flex-column align-items-center" [class.justify-content-center]="(isSaving$ | async) || (isSaved$ | async)">
    <ng-container *ngIf="!(isSaving$ | async) && !(isSaved$ | async)">
      <h2 class="mb-4">Rate your session</h2>
      <div class="d-flex star-rating mb-4" (mouseleave)="tentativeRating = rating; hovering = false;">
        <fa-icon class="px-2" [icon]="(rating >= 1 && !hovering) || (tentativeRating >= 1 && hovering) ? ['fas', 'star'] : ['far', 'star']" (click)="onRatingClick(1)" (mouseover)="tentativeRating = 1; hovering = true;"></fa-icon>
        <fa-icon class="px-2" [icon]="(rating >= 2 && !hovering) || (tentativeRating >= 2 && hovering) ? ['fas', 'star'] : ['far', 'star']" (click)="onRatingClick(2)" (mouseover)="tentativeRating = 2; hovering = true;"></fa-icon>
        <fa-icon class="px-2" [icon]="(rating >= 3 && !hovering) || (tentativeRating >= 3 && hovering) ? ['fas', 'star'] : ['far', 'star']" (click)="onRatingClick(3)" (mouseover)="tentativeRating = 3; hovering = true;"></fa-icon>
        <fa-icon class="px-2" [icon]="(rating >= 4 && !hovering) || (tentativeRating >= 4 && hovering) ? ['fas', 'star'] : ['far', 'star']" (click)="onRatingClick(4)" (mouseover)="tentativeRating = 4; hovering = true;"></fa-icon>
        <fa-icon class="px-2" [icon]="(rating >= 5 && !hovering) || (tentativeRating >= 5 && hovering) ? ['fas', 'star'] : ['far', 'star']" (click)="onRatingClick(5)" (mouseover)="tentativeRating = 5; hovering = true;"></fa-icon>
      </div>
      <ng-container *ngIf="showFeedbackOptions">
        <h3 class="mb-4">
          <ng-container *ngIf="isPositive$ | async; else negative">
            What was great about the session?
          </ng-container>
          <ng-template #negative>
            What could we do to improve next time?
          </ng-template>
        </h3>

        <div class="animate-opacity feedback-options d-flex flex-wrap justify-content-center mb-4">
          <div *ngFor="let option of (feedbackOptions$ | async)" class="option body-copy" [class.selected]="selectedFeedback.includes(option.id)" (click)="onFeedbackClick(option.id)">
            {{ option.title }}
          </div>
        </div>
        <div class="animate-opacity w-100 px-2 mb-4">
          <textarea class="form-control" rows="5" placeholder="Tell us more..." [(ngModel)]="additionalComments"></textarea>
        </div>
        <button class="w-100 btn btn-purple" (click)="submitStarRating()">Submit</button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isSaving$ | async">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </ng-container>

    <ng-container *ngIf="isSaved$ | async">
      <h2>Thank you for your feedback</h2>
    </ng-container>

  </div>
  <div class="bottom-border"></div>
</div>

