import { createAction, props } from '@ngrx/store';
import { SaveAnswersRequest, SpotlightResponse, SurveyCreateRequest, SurveyCreateResponse, SurveyInProgressRequest } from '../models/spotlight-response.interface';
import { GetQuestionnaireResponse } from '../services/questionnaire-api.service';

export namespace LoadSurveyFromInvite {
  export const request = createAction(
    '[Survey] Load Survey From Invite Request',
    props<{
      uniqueRef: string,
      spotlightResponseId: number
    }>()
  );

  export const success = createAction(
    '[Survey] Load Survey From Invite Success',
    props<{ data: SpotlightResponse }>()
  );

  export const failure = createAction(
    '[Survey] Load Survey From Invite Failure',
    props<{ error: any }>()
  )
}

export namespace LoadSurveyFromSpotlightGroup {
  export const request = createAction(
    '[Survey] Load Survey From Spotlight Id Request',
    props<{
      spotlightId: number,
      creditPurchaseId: number
    }>()
  );

  export const oldRequest = createAction(
    '[Survey] Load Survey From Spotlight Group Request',
    props<{
      spotlightGroup: string
    }>()
  );

  export const success = createAction(
    '[Survey] Load Survey From Spotlight Id Success',
    props<{ data: SpotlightResponse }>()
  );

  export const failure = createAction(
    '[Survey] Load Survey From Spotlight Id Failure',
    props<{ error: any }>()
  );
}

export namespace LoadSurveyInProgress {
  export const request = createAction(
    '[Survey] Load Survey In Progress Request',
    props<{ request: SurveyInProgressRequest }>()
);

  export const success = createAction(
    '[Survey] Load Survey In Progress Success',
    props<{ data: SpotlightResponse }>()
);

  export const failure = createAction(
    '[Survey] Load Survey In Progress Failure',
    props<{ error: any}>()
);
}

export namespace CreateSurvey {
  export const request = createAction(
    '[Survey] Create Survey Request',
    props<{ request: SurveyCreateRequest }>()
  );

  export const success = createAction(
    '[Survey] Create Survey Success',
    props<{ data: SurveyCreateResponse }>()
  );

  export const failure = createAction(
    '[Survey] Create Survey Failure',
    props<{ error: any}>()
  );
}

export namespace UpdateSurvey {
  export const request = createAction(
    '[Survey] Update Survey Request',
    props<{ uniqueRef: string, spotlightResponseId: number, request: SurveyCreateRequest }>()
  );

  export const success = createAction(
    '[Survey] Update Survey Success',
    props<{ data: SurveyCreateResponse }>()
  );

  export const failure = createAction(
    '[Survey] Update Survey Failure',
    props<{ error: any}>()
  );
}

export namespace SaveSurveyAnswers {
  export const request = createAction(
    '[Survey] Save Survey Answers Request',
    props<{ request: SaveAnswersRequest }>()
  );

  export const success = createAction(
    '[Survey] Save Survey Answers Success',
    props<{ data: GetQuestionnaireResponse[] }>()
  );

  export const failure = createAction(
    '[Survey] Save Survey Answers Failure',
    props<{ error: any}>()
  );
}

export namespace GetLinkedQuestionnaires {
  export const request = createAction(
    '[Survey] Get Linked Questionnaires Request',
    props<{ spotlightResponseId: number, uniqueRef: string }>()
  );

  export const success = createAction(
    '[Survey] Get Linked Questionnaires Success',
    props<{ data: GetQuestionnaireResponse[] }>()
  );

  export const failure = createAction(
    '[Survey] Get Linked Questionnaires Failure',
    props<{ error: any }>()
  );
}

export const setSurveyPage = createAction(
  '[Survey] Set Page',
  props<{ pageId: number }>()
);

export const previousPage = createAction(
  '[Survey] Previous Page'
);

export const nextPage = createAction(
  '[Survey] Next Page'
);

export const setSpotlightResponseId = createAction(
  '[Survey] Set Spotlight Response Id',
  props<{ spotlightResponseId: number }>()
);

export const setUniqueRef = createAction(
  '[Survey] Set Unique Ref',
  props<{ uniqueRef: string }>()
);

export const checkExistingSurvey = createAction(
  '[Survey] Check Existing Survey',
  props<{ uniqueRef: string, spotlightResponseId: number }>()
);

export const setLanguageCode = createAction(
  '[Survey] Set Language Code',
  props<{ languageCode: string}>()
  )
