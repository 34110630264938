<div class="h-100">
    <div class="row g-0 h-100">
        <div class="col-md-4 bg-primary welcome-container">
            <img class="bg-logo" src="./assets/svg/Mindflick_Monogram_Colour_RGB.svg" />
            <img class="bg-pattern" src="./assets/svg/nudge-patterns/pages-bg-pattern.svg" />
            <img class="logo position-relative" src="./assets/images/logos/mindflick.svg" />

            <div class="mt-5 d-flex flex-column greeting position-relative">
                <h1 class="text-white pt-5 pb-0 mb-0 align-middle">WELCOME</h1>
                <h1 class="text-white" *ngIf="name">
                    {{ name.toUpperCase() }}
                </h1>
            </div>
        </div>
        <div class="col-md-8 p-5 survey-page d-flex flex-column">
            <h2 class="mb-3 thank-you-heading">Thank you for completing the Spotlight Questionnaire</h2>
            <div *ngIf="userType == 1">
                <h3 class="thank-you-copy">We understand your anticipation to view your profile immediately, but we believe in making sure that you have the best experience when seeing your profile for the first time.</h3>
                <h3 class="thank-you-copy">Context is important, so you’ll be able to dive into your profile during the accreditation course, ensuring you are equipped with the knowledge and tools for the best possible debrief.</h3>
                <h3 class="thank-you-copy">In the meantime, continue to the Spotlight dashboard to get set up for your course.</h3>
              </div>

              <div *ngIf="userType != 1">
                <h3 class="thank-you-copy">We understand your anticipation to view your Spotlight profile immediately, but we believe in making sure that you have the best experience when seeing your profile for the first time.</h3>
                <h3 class="thank-you-copy">Your practitioner has received a copy of your profile and will be in touch to arrange a debrief with you.</h3>
                <h3 class="thank-you-copy">Sit tight in the meantime!</h3>
              </div>
      
              <img class="end-of-questionnaire mt-5" style="width: 100%" src="../../assets/images/end-of-questionnaire.jpg" />
              
              <div class="d-flex justify-content-end mt-4">
                <button *ngIf="userType == 1" class="btn btn-primary" (click)="navigateToDashboard()">
                  Go to dashboard
                </button>
              </div>
        </div>
    </div>
</div>