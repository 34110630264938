import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import {
  setBackground,
  setHeaderLogo,
  setTitleBar,
} from '../actions/layout.actions';
import {
  CreateSurvey,
  GetLinkedQuestionnaires,
  LoadSurveyFromInvite,
  LoadSurveyFromSpotlightGroup,
  LoadSurveyInProgress,
  setLanguageCode,
  setSpotlightResponseId,
  setUniqueRef,
  UpdateSurvey,
} from '../actions/survey.actions';
import {
  SurveyCreateRequest,
  SurveyInProgressRequest,
} from '../models/spotlight-response.interface';
import { AppState } from '../reducers';
import { BackgroundStyle, HeaderLogo } from '../reducers/layout.reducer';
import {
  hasInProgress,
  selectLanguageCode,
  survey,
} from '../selectors/survey.selectors';
import { SurveyService } from '../services/survey.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../languages';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  signUpForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    companyName: new FormControl(''),
    gender: new FormControl('', Validators.required),
    genderOther: new FormControl(''),
    age: new FormControl(),
  });

  pageToRouteTo?: number;
  uniqueRef: string = '';
  spotlightResponseId: number = 0;
  spotlightGroup: string = '';
  spotlightId: number = 0;
  creditPurchaseId: number = 0;
  showAllErrors: boolean = false;

  passwordCopy: string = '';

  inProgress: boolean = false;

  subs = new Subscription();

  survey$ = this.store.select(survey);
  hasInProgress$ = this.store.select(hasInProgress);
  languageCode$ = this.store.select(selectLanguageCode);

  langOptions = languages;

  selectedOption: string = 'en';
  originalLang?: string;

  destroyed$ = new Subject<boolean>();

  constructor(
    public surveyService: SurveyService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private modalService: BsModalService,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    var browserLang = this.translateService.getBrowserLang()!;
    this.translateService.use(browserLang ? browserLang : 'en');
    this.selectedOption =
      this.langOptions.find((x) => x.value == browserLang)?.name != undefined
        ? this.langOptions.find((x) => x.value == browserLang)?.name!
        : 'English - EN';
  }

  ngOnInit(): void {
    this.languageCode$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((langCode) => {
        if (langCode) {
          this.translateService.use(
            this.langOptions.find((x) => x.name == langCode)?.value!
          );
          this.selectedOption = this.langOptions.find(
            (x) => x.name == langCode
          )?.name!;
          if (!this.originalLang)
            this.originalLang = this.langOptions.find(
              (x) => x.name == langCode
            )?.value!;
        } else {
          this.translateService.setDefaultLang('en');

          const browserLang = this.translateService.getBrowserLang();
          this.translateService.use(
            browserLang && browserLang.match(/en|fr|pt|nl|da|it|es|de/)
              ? browserLang
              : 'en'
          );

          this.selectedOption =
            this.langOptions.find((x) => x.value == browserLang)?.name !=
              undefined
              ? this.langOptions.find((x) => x.value == browserLang)?.name!
              : 'English - EN';
          if (!this.originalLang) this.originalLang = browserLang;
        }
      });

    //Perform the UI layout in a moment, not in the ngOnInit to avoid ExpressionChangedAfterItHasBeenCheckedError exceptions
    setTimeout(() => {
      this.store.dispatch(setTitleBar({ visible: true }));
      this.store.dispatch(setHeaderLogo({ logo: HeaderLogo.Spotlight }));
    });
    this.route.paramMap.pipe(delay(0), take(1)).subscribe((params) => {
      this.spotlightResponseId = Number(params.get('spotlightResponseId')) ?? 0;
      this.uniqueRef = params.get('uniqueRef') ?? '';

      this.creditPurchaseId = Number(params.get('creditPurchaseId')) ?? 0;
      this.spotlightId = Number(params.get('spotlightGroup')) ?? 0;
      this.spotlightGroup = params.get('oldSpotlightUniqueRef') ?? '';

      if (this.spotlightResponseId && this.uniqueRef) {
        this.store.dispatch(
          LoadSurveyFromInvite.request({
            uniqueRef: this.uniqueRef,
            spotlightResponseId: this.spotlightResponseId,
          })
        );
        this.store.dispatch(
          setSpotlightResponseId({
            spotlightResponseId: this.spotlightResponseId,
          })
        );
        this.store.dispatch(setUniqueRef({ uniqueRef: this.uniqueRef }));
      } else if (this.spotlightId && this.creditPurchaseId) {
        this.store.dispatch(
          LoadSurveyFromSpotlightGroup.request({
            spotlightId: this.spotlightId,
            creditPurchaseId: this.creditPurchaseId
          })
        );
      } else if (this.spotlightGroup != '') {
        this.store.dispatch(
          LoadSurveyFromSpotlightGroup.oldRequest({
            spotlightGroup: this.spotlightGroup
          })
        );
      }
    });

    this.survey$
      .pipe(
        takeUntil(this.destroyed$),
        filter((x) => x !== undefined)
      )
      .subscribe((survey) => {
        this.signUpForm.setValue({
          email: survey?.email ?? '',
          firstName: survey?.firstName ?? '',
          lastName: survey?.lastName ?? '',
          companyName: survey?.companyName ?? '',
          gender:
            survey?.gender && survey?.gender.length > 1
              ? 'o'
              : survey?.gender ?? '',
          genderOther:
            survey?.gender && survey.gender.length > 1 ? survey.gender : '',
          age: survey?.age !== 0 ? survey?.age : null,
        });
        this.signUpForm.updateValueAndValidity();

        this.spotlightResponseId = survey?.id ?? 0;
        this.uniqueRef = survey?.uniqueRef ?? '';
        if (survey?.creditPurchaseId)
          this.creditPurchaseId = survey.creditPurchaseId;
        if (survey?.spotlightId)
          this.spotlightId = survey.spotlightId;

        let answerIndex = survey?.answers?.indexOf('-');
        this.pageToRouteTo = answerIndex ? answerIndex / 4 : answerIndex;

        if (answerIndex === -1) {
          this.store.dispatch(
            GetLinkedQuestionnaires.request({
              spotlightResponseId: this.spotlightResponseId,
              uniqueRef: this.uniqueRef,
            })
          );
          this.pageToRouteTo = 20;
          this.navigateToSurveyPage();
        }
      });

    this.subs.add(
      this.hasInProgress$.subscribe((hasInProgress) => {
        this.inProgress = hasInProgress;
      })
    );

  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }

    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  checkForProgress() {
    if (!this.spotlightId || !this.creditPurchaseId || this.signUpForm.controls.email.invalid) {
      return;
    }

    const request: SurveyInProgressRequest = {
      email: this.signUpForm.value.email ?? '',
      spotlightId: this.spotlightId,
      creditPurchaseId: this.creditPurchaseId
    };

    this.store.dispatch(LoadSurveyInProgress.request({ request }));
  }

  navigateToSurveyPage() {
    if (!this.pageToRouteTo) {
      this.router.navigate([
        `survey/${this.uniqueRef}/${this.spotlightResponseId}`,
      ]);
    } else {
      this.router.navigate([
        `survey/${this.uniqueRef}/${this.spotlightResponseId}/page/${this.pageToRouteTo}`,
      ]);
    }
  }

  showPrivacyPolicy() {
    this.modalService.show(PrivacyPolicyComponent, { class: 'modal-xl' });
  }

  setLanguage() {
    var lang = this.langOptions.find((x) => x.name == this.selectedOption)
      ?.value!;
    this.translateService.use(lang);
    this.store.dispatch(setLanguageCode({ languageCode: this.selectedOption }));

    if (this.originalLang != lang) {
      const request: SurveyInProgressRequest = {
        email: this.signUpForm.value.email ?? '',
        spotlightId: this.spotlightId,
        creditPurchaseId: this.creditPurchaseId
      };

      this.store.dispatch(LoadSurveyInProgress.request({ request }));
      this.originalLang = lang;
    }
  }

  onSubmit(): void {
    this.showAllErrors = true;

    if (this.signUpForm.value.gender == '') {
      this.signUpForm.markAllAsTouched()
    }

    if (this.signUpForm.invalid) {
      return;
    }

    const formValue = this.signUpForm.value;
    const request: SurveyCreateRequest = {
      spotlightId: this.spotlightId,
      creditPurchaseId: this.creditPurchaseId,
      email: formValue.email ?? '',
      firstName: formValue.firstName ?? '',
      lastName: formValue.lastName ?? '',
      age: formValue.age,
      companyName: formValue.companyName ?? '',
      gender:
        formValue.gender === 'o'
          ? formValue.genderOther ?? ''
          : formValue.gender ?? '',
      emailPermission: true,
    };

    if (this.uniqueRef && this.spotlightResponseId) {
      this.store.dispatch(
        UpdateSurvey.request({
          uniqueRef: this.uniqueRef,
          spotlightResponseId: this.spotlightResponseId,
          request,
        })
      );
    } else {
      this.store.dispatch(CreateSurvey.request({ request }));
    }
  }

  setGenderTouched() {
    this.signUpForm.controls.gender.markAsTouched();
  }
}
