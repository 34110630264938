<div *ngIf="questionType == QuestionnaireQuestionTypes.CustomLikertScale" class="d-flex justify-content-between pt-15">
  <div *ngFor="let label of customLabels; index as i" class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" [name]="'likert-custom' + questionId" type="radio" [value]="(i + 1)" [(ngModel)]="value" (change)="emitLikertValue()">
    <label class="form-label text-center small-text mt-1">{{ label }}</label>
  </div>
</div>

<form *ngIf="questionType == QuestionnaireQuestionTypes.RateOutOf10" class="d-flex justify-content-between pt-15 overflow-auto" [formGroup]="fgLikert">
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio1" value="1" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio1">1</label>
  </div>
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio2" value="2" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio2">2</label>
  </div>
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio3" value="3" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio3">3</label>
  </div>
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio4" value="4" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio4">4</label>
  </div>

  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio5" value="5" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio5">5</label>
  </div>

  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio6" value="6" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio6">6</label>
  </div>

  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio7" value="7" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio7">7</label>
  </div>

  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio8" value="8" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio8">8</label>
  </div>

  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio9" value="9" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio9">9</label>
  </div>

  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio10" value="10" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio10">10</label>
  </div>
</form>

<form *ngIf="questionType == QuestionnaireQuestionTypes.LikertScale" class="d-flex justify-content-between pt-15" [formGroup]="fgLikert">
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio1" value="Strongly disagree" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio1">Strongly disagree</label>
  </div>
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio2" value="Disagree" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio2">Disagree</label>
  </div>
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio3" value="Neutral" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio3">Neutral</label>
  </div>
  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio4" value="Agree" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio4">Agree</label>
  </div>

  <div class="form-check form-check-inline d-flex flex-column">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio5" value="Strongly agree" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio5">Strongly agree</label>
  </div>
</form>

<form *ngIf="questionType == QuestionnaireQuestionTypes.YesNo" class="d-flex justify-content-center pt-15" [formGroup]="fgLikert">
  <div class="form-check form-check-inline d-flex flex-column mx-5">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio1" value="No" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio1">No</label>
  </div>
  <div class="form-check form-check-inline d-flex flex-column mx-5">
    <input class="form-check-input m-0 align-self-center" type="radio" id="inlineRadio2" value="Yes" formControlName="fcLikertValue">
    <label class="form-label text-center small-text mt-1" for="inlineRadio2">Yes</label>
  </div>
</form>