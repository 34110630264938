import { createAction, props } from '@ngrx/store';
import { CoachingSessionFeedback } from '../models/coaching-session-feedback.interface';
import { CoachingSession } from '../models/coaching-session.interface';
import { FeedbackOption, SaveStarRatingModel } from '../models/star-rating.interface';
import { GetQuestionnaireResponse, QuestionnaireAnswer, QuestionnaireDetail } from '../services/questionnaire-api.service';

//The load questionnaire action will fetch the details from the server and popilate the store accordingly
export const loadQuestionnaire = createAction(
  '[Questionnaire] Load Questionnaire',
  props<{
    uniqueRef: string;
    questionnaireResponseId: number
  }>()
);

export const loadQuestionnaireSuccess = createAction(
  '[Questionnaire] Load Questionnaire Success',
  props<{ data: GetQuestionnaireResponse }>()
);

export const loadQuestionnaireFailure = createAction(
  '[Questionnaire] Load Questionnaire Failure',
  props<{ error: any }>()
);

//Filters the list of questions to be the specific page and links up the existing answers
export const setQuestionnairePage = createAction(
  '[Questionnaire] Set Page',
  props<{
    pageId: number;
  }>()
);

export const nextQuestionnairePage = createAction(
  '[Questionnaire] Next Page'
);

export const setQuestionnaireAnswer = createAction(
  '[Questionnaire] Set Answer',
  props<{
    questionnaireQuestionId: number;
    value: number;
    textValue: string;
  }>()
);

export const saveAnswers = createAction(
  '[Questionnaire] Save Answers',
  props<{
    nextPageOnceSaved: boolean,
    markComplete: boolean,
    answers: QuestionnaireAnswer[]
  }>()
);

export const saveAnswersSuccess = createAction(
  '[Questionnaire] Save Answers Success',
  props<{
    nextPageOnceSaved: boolean
  }>()
);

export const saveAnswersFailure = createAction(
  '[Questionnaire] Save Answers Failure',
  props<{ error: any }>()
);

export namespace LoadQuestionnaireDetails {
  export const request = createAction(
    '[Questionnaire] Load Questionnaire Details Request',
    props<{
      questionnaireId: number,
      spotlightResponseId: number,
      uniqueRef: string
    }>()
  );

  export const success = createAction(
    '[Questionnaire] Load Questionnaire Details Success',
    props<{ response: QuestionnaireDetail }>()
  );

  export const failure = createAction(
    '[Questionnaire] Load Questionnaire Details Failure',
    props<{ error: any }>()
  );
}

export namespace CreateQuestionnaireResponse {
  export const request = createAction(
    '[Questionnaire] Create Questionnaire Response Request',
    props<{
      questionnaireId: number,
      spotlightResponseId: number,
      uniqueRef: string,
      respondentName: string,
      respondentCohort: string,
      respondentEmail: string
    }>()
  );

  export const success = createAction(
    '[Questionnaire] Create Questionnaire Response Success',
    props<{ data: GetQuestionnaireResponse, uniqueRef: string }>()
  );

  export const failure = createAction(
    '[Questionnaire] Create Questionnaire Response Failure',
    props<{ error: any }>()
  );
}

export namespace GetFeedbackOptions {
  export const Request = createAction(
    '[Questionnaire] Get Feedback Options Request',
  );

  export const Success = createAction(
    '[Questionnaire] Get Feedback Options Success',
    props<{ options: FeedbackOption[] }>()
  );

  export const Fail = createAction(
    '[Questionnaire] Get Feedback Options Fail',
    props<{ error: any }>()
  );
}

export const SetShowPositiveFeedbackOptions = createAction(
  '[Questionnaire] Set Show Positve Feedback Options',
  props<{ positive: boolean }>()
);

export const SetShowGroupFeedbackOptions = createAction(
  '[Questionnaire] Set Show Group Feedback Options',
  props<{ isGroup: boolean }>()
);

export namespace SaveStarRating {
  export const Request = createAction(
    '[Questionnaire] Save Star Rating Request',
    props<{ body: SaveStarRatingModel }>()
  );

  export const Success = createAction(
    '[Questionnaire] Save Star Rating Success',
  );

  export const Fail = createAction(
    '[Questionnaire] Save Star Rating Fail',
    props<{ error: any }>()
  );
}



export namespace GetCoachingSessionInfo {
  export const Request = createAction(
    '[Questionnaire] Get Coaching Session Info Request',
    props<{ sessionId: number; }>());

  export const Success = createAction(
    '[Questionnaire] Get Coaching Session Info Success',
    props<{ session: CoachingSession; }>());

  export const Fail = createAction(
    '[Questionnaire] Get Coaching Session Info Fail',
    props<{ error:any }>());
}

export namespace SaveCoachingSessionFeedback {
  export const Request = createAction(
    '[Questionnaire] Save Coaching Session Feedback Request',
    props<{ sessionFeedback: CoachingSessionFeedback; }>());

  export const Success = createAction(
    '[Questionnaire] Save Coaching Session Feedback Success',
  );

  export const Fail = createAction(
    '[Questionnaire] Save Coaching Session Feedback Fail',
    props<{ error: any; }>());
} 

