import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-btn-action',
  templateUrl: './btn-action.component.html',
  styleUrls: ['./btn-action.component.scss'],
})
export class BtnActionComponent implements OnInit {
  @Input()
  icon?: IconProp;

  @Input()
  iconDimensions: number = 50;

  @Input()
  secondary = false;

  @Input()
  light = false;

  @Input()
  disabled = false;

  @Input() svgPath?: string;

  @Input() primary = false;

  @Input() noBorder = false;

  @Output()
  actionClick = new EventEmitter<MouseEvent>();

  @Input() imgWidth?: number;

  @Input() isReflection = false;

  @Input() isSmallIcon = false;

  constructor() {}

  ngOnInit(): void {}

  actionClicked(event: MouseEvent) {
    this.actionClick.emit(event);
  }
}
