import { setTheme } from 'ngx-bootstrap/utils';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fader } from './route-animations';
import { Store } from '@ngrx/store';
import * as fromLayout from './selectors/layout.selectors';
import * as layoutActions from './actions/layout.actions';
import { AppState } from './reducers';
import { BackgroundStyle } from './reducers/layout.reducer';
import { LoggingService } from './logging.service';
import { TranslateService } from '@ngx-translate/core';
import { selectLanguageCode, selectSupportedLanguages, selectSurvey } from './selectors/survey.selectors';
import { languages } from './languages';
import { SelectValue } from './models/ui-models.interface';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ // <-- add your animations here
    fader,
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  langCode$ = this.store.select(selectLanguageCode);
  survey$ = this.store.select(selectSurvey);

  languages = languages;
  languageOptions: SelectValue[] = languages.map(l => ({
    value: l.value,
    description: l.name
  }));

  language = 'en';

  title = 'ClientApp';
  constructor(
    private store: Store<AppState>,
    private logging: LoggingService,
    private translate: TranslateService
  ) {
    //Tell ngx-bootstrap to use v4 of the styles
    setTheme('bs4');

    translate.addLangs(['en', 'fr', 'pt', 'nl', 'da', 'it', 'es', 'de', 'pl', 'hi', 'ar', 'ja']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang && browserLang.match(/en|fr|pt|nl|da|it|es|de|pl|hi|ar|ja/) ? browserLang : 'en');

    this.language = browserLang ?? 'en';
  }

  ngOnInit() {
    this.subs.add(
      this.supportedLanguages$.subscribe((langIds) => {

        if (langIds == undefined || langIds.length == 0)
          return;

        this.languageOptions = this.languages.filter(x => langIds.findIndex(l => l.startsWith(x.value)) > -1).map(l => ({
          value: l.value,
          description: l.name
        }));
      }));
  }

  subs = new Subscription();
  ngOnDestroy(): void {
    //destroy all the subscriptions at once
    if (this.subs != null) {
      this.subs.unsubscribe();
    }
  }

  public BackgroundStyle = BackgroundStyle;
  showTitleBar$ = this.store.select(fromLayout.titleBarVisible);
  backgroundStyle$ = this.store.select(fromLayout.backgroundStyle);
  headerLogo$ = this.store.select(fromLayout.headerLogo);
  supportedLanguages$ = this.store.select(selectSupportedLanguages);



  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  setBackground(style: BackgroundStyle) {
    this.store.dispatch(layoutActions.setBackground({ style }));
  }

}

