<h2 class="mb-3">Building your Spotlight profile</h2>
<div class="body-copy border-bottom-grey pb-2 mb-4">
  <p>
    Developing adaptability and building stronger connections between people is
    key to consistent high performance for both individuals and teams. This
    starts with building your own personalised Spotlight profile.
  </p>
  <p>
    Spotlight has been designed to help you develop a greater understanding of
    your own mindset and behavioural preferences. Completing this short
    questionnaire will provide you with personalised insights into ways of
    getting the best out of yourself and those around you.
  </p>
  <p>A few important things to know before you get started:</p>
  <ul>
    <li class="pb-2">
      Completing the questionnaire should take no more than 10-15 minutes. We
      recommend you find the time and space to complete this in one sitting.
    </li>
    <li class="pb-2">
      There are no right, wrong or best answers, so try not to overthink your
      answers - if you're uncertain, go with your gut as first impressions are
      generally best.
    </li>
  </ul>
</div>
<h2 class="mb-3">How it works</h2>
<div class="body-copy border-bottom-grey pb-4 mb-4">
  <p>
    You will see four statements for each question. Read each statement
    carefully and choose one that resonates the most with your character. Drag
    this statement into the 'Most like me' box.
  </p>
  <p>
    Next, choose a statement which resonates least with your character and drag
    this into the 'Least like me' box. Place the remaining statements anywhere
    in-between, based on how accurately they represent you.
  </p>
  <!--<p>
    Click play below to see a short demo video of how to fill out each question.
  </p>
  <div class="col-md-12">-->
  <!-- DEMO VIDEO GOES HERE -->
  <!--</div>-->
</div>
<button class="btn btn-purple float-end" (click)="navigateSurvey()">
  Build My Profile
</button>
