import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';


export const selectCurrentPageId = (state: AppState) => state.survey.currentPage;
export const selectCurrentPageIsLastPage = (state: AppState) => state.survey.currentPageIsLastPage;
export const selectSurvey = (state: AppState) => state.survey.survey;
export const selectHasInProgress = (state: AppState) => state.survey.hasInProgress;
export const selectAssociatedQuestionnaires = (state: AppState) => state.survey.associatedQuestionnaires;
export const selectIsLoading = (state: AppState) => state.survey.isLoading;
export const selectIsSaving = (state: AppState) => state.survey.isSaving;

export const survey = createSelector(
  selectSurvey,
  (survey) => (survey)
);

export const selectSupportedLanguages = createSelector(
  selectSurvey,
  (survey) => (survey?.supportedLanguages ?? [])
);

export const onSurveyIntroPage = createSelector(
  selectCurrentPageId,
  (pageId) => {
    return pageId < -1;
  }
);

export const onSurveyDemoPage = createSelector(
  selectCurrentPageId,
  (pageId) => {
    return pageId === -1
  }
);

export const onLastSurveyPage = createSelector(
  selectCurrentPageId,
  selectSurvey,
  (pageId, survey) => {
    return survey?.pages != null && pageId == survey?.pages.length;
  }
);

export const currentPageWordPairs = createSelector(
  selectSurvey,
  selectCurrentPageId,
  (survey, currentPage) => {
    return survey?.pages[currentPage]?.wordPairs;
  }
);

export const currentPageAnswers = createSelector(
  selectSurvey,
  selectCurrentPageId,
  (survey, currentPage) => {
    const questionIndex = currentPage * 4;
    return survey?.answers?.substring(questionIndex, questionIndex + 4);
  }
);

export const hasInProgress = createSelector(
  selectHasInProgress,
  (hasInProgress) => (hasInProgress)
);

export const associatedQuestionnaires = createSelector(
  selectAssociatedQuestionnaires,
  (associatedQuestionnaires) => (associatedQuestionnaires)
);

export const selectLanguageCode = (state: AppState) => state.survey.languageCode;

