import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SaveAnswersRequest, SpotlightResponse, SurveyCreateRequest, SurveyCreateResponse, SurveyInProgressRequest } from '../models/spotlight-response.interface';
import { environment } from '../../environments/environment';
import { GetQuestionnaireResponse, GetSpotlightGroupOwnerResponse } from './questionnaire-api.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private lastApiResponse: SpotlightResponse | undefined;
  private url = './api/survey';

  constructor(private http: HttpClient) { }

  getInviteId(spotlightResponseId: number, uniqueRef: string, langId: string): Observable<SpotlightResponse> {
    return this.http.get<SpotlightResponse>(`${this.url}/invite/${spotlightResponseId}/${uniqueRef}/${langId}`);
  }

  getSpotlightId(creditPurchaseId: number, spotlightId: number, langId: string) {
    return this.http.get<SpotlightResponse>(`${this.url}/spotlightid/${creditPurchaseId}/${spotlightId}/${langId}`);
  }

  getSpotlightGroup(spotlightGroup: string, langId: string) {
    return this.http.get<SpotlightResponse>(`${this.url}/spotlightgroup/${spotlightGroup}/${langId}`);
  }

  getInProgress(request: SurveyInProgressRequest, langId: string): Observable<SpotlightResponse> {
    const req = {
      ...request,
      langId: langId
    }
    return this.http.post<SpotlightResponse>(`${this.url}/inprogress`, req);
  }

  createSurvey(request: SurveyCreateRequest): Observable<SurveyCreateResponse> {
    return this.http.post<SurveyCreateResponse>(`${this.url}/create`, request);
  }

  updateSurvey(uniqueRef: string, spotlightResponseId: number, request: SurveyCreateRequest) {
    return this.http.post<SurveyCreateResponse>(`${this.url}/update/${spotlightResponseId}/${uniqueRef}`, request);
  }

  saveProgress(request: SaveAnswersRequest) {
    return this.http.post<GetQuestionnaireResponse[]>(`${this.url}/save`, request);
  }

  getLinkedQuestionnaires(spotlightResponseId: number, uniqueRef: string) {
    return this.http.get<GetQuestionnaireResponse[]>(`${this.url}/linked-questionnaires/${spotlightResponseId}/${uniqueRef}`);
  }

  getSpotlightGroupOwner(spotlightResponseId: number) {
    return this.http.get<GetSpotlightGroupOwnerResponse>(`${this.url}/spotlight-group-owner/${spotlightResponseId}/
    `);
  }
}
