import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppState } from '../reducers';
import { selectSurvey } from '../selectors/survey.selectors';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent {
  name?: string;

  constructor(
    private store: Store<AppState>,
  ) { }

  subs = new Subscription();

  survey$ = this.store.select(selectSurvey);

  spotlightResponseId?: number;
  uniqueRef?: string = '';
  showUnselectedRanks: boolean = false;
  ownerFirstName: string = '';
  isLocked: boolean = false;
  userType: any;

   ngOnInit(): void {
    this.userType = sessionStorage.getItem('type');

    this.subs.add(
      this.survey$.subscribe((survey) => {
        this.name = survey?.firstName;
      })
    );
  }

  ngOnDestroy(): void {
    //destroy all the subscriptions at once
    if (this.subs != null) {
      this.subs.unsubscribe();
    }
  }

  navigateToDashboard(): void {
    sessionStorage.removeItem('type');
    window.location.href = environment.spotlightUrl;
  }
}
