<div #currentSelection
     class="form-control d-flex current-selection"
     [class.form-control-light]="light"
     [class.ng-readonly]="readonly"
     [class.ng-touched]="touched && !readonly"
     [class.ng-invalid]="invalid"
     (focus)="showOptionsOn()"
     (click)="showOptionsOn()"
     id="dropdownSelect"
     tabindex="0">
  <div id="selectedOption"
       *ngIf="!showOptions && this.selectedOption?.description != null"
       class="flex-fill"
       [class.text-black]="!readonly"
       [class.text-readonly]="readonly">
    {{ this.selectedOption?.description }}
  </div>
  <div id="dropdownPlaceholder"
       [class.grey]="!touched"
       *ngIf="!showOptions && this.selectedOption?.description == null"
       class="flex-fill"
     >
    {{ this.placeholder }}
  </div>
  <input *ngIf="showOptions"
         (keydown.tab)="showOptionsOff()"
         class="form-control p-0 m-0 search-input"
         #searchInput
         [readonly]="readonly"
         [(ngModel)]="filter" />
  <fa-icon [icon]="dropdown"></fa-icon>
</div>
<div #selectOptions
     *ngIf="showOptions"
     class="options"
     [style.width.px]="currentSelection.offsetWidth"
     [style.top.px]="dropUp ? (8 + selectOptions.offsetHeight) * -1 : undefined"
     id="dropdownList">
  <div *ngFor="let option of filteredOptions"
       class="option"
       [class.option-highlighted]="option.value === hoveredOption?.value"
       (click)="writeValue(option.value)">
    {{ option.description }}
  </div>
</div>
