import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, filter, takeUntil } from 'rxjs';
import { QuestionnaireQuestionTypes } from 'src/app/models/ui-models.interface';

@Component({
  selector: 'app-likert',
  templateUrl: './likert.component.html',
  styleUrls: ['./likert.component.scss'],
})
export class LikertComponent implements OnInit, OnDestroy {
  @Input() questionType: QuestionnaireQuestionTypes =
    QuestionnaireQuestionTypes.CustomLikertScale;
  @Input() questionId = -1;

  @Input() likert?: number;
  @Output() likertChange = new EventEmitter<number>();

  @Input() textLikert?: string;
  @Output() textLikertChange = new EventEmitter<string>();

  @Input() customLabels: string[] = [];

  destroyed$ = new Subject<boolean>();

  fgLikert = new FormGroup({
    fcLikertValue: new FormControl<string | null>(null, Validators.required),
  });

  value?: number;
  QuestionnaireQuestionTypes = QuestionnaireQuestionTypes;

  ngOnInit() {
    this.value = this.likert;
    this.fgLikert.controls.fcLikertValue.setValue(
      this.likert?.toString() ?? null
    );

    this.fgLikert.controls.fcLikertValue.valueChanges
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe((x) => {
        this.likertChange.emit(parseInt(x!));
        this.textLikertChange.emit(x!);
      });
  }

  emitLikertValue() {
    this.likertChange.emit(this.value);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
