<div class="h-100 d-flex flex-column">
  <header class="navbar banner py-2 d-flex justify-content-between" *ngIf="showTitleBar$ | async">
    <div class="flex-grow-1" [ngSwitch]="headerLogo$ | async">
      <img *ngSwitchCase="0" src="./assets/images/logos/mindflick.svg" />
      <img *ngSwitchCase="1" src="./assets/svg/spotlight-white.svg" />
      <img *ngSwitchDefault src="./assets/images/logos/mindflick.svg" />
    </div>
  </header>

  <main [@routeAnimations]="prepareRoute(outlet)" class="flex-fill">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
</div>
