import { createReducer, on } from '@ngrx/store';
import { QuestionnaireAnswer, QuestionnaireDetail, QuestionnairePage, QuestionnaireQuestion } from '../services/questionnaire-api.service';
import * as questionnaireActions from '../actions/questionnaire.actions';
import { FeedbackOption } from '../models/star-rating.interface';
import { CoachingSession } from '../models/coaching-session.interface';



export const questionnaireFeatureKey = 'questionnaire';

export interface State {
  uniqueRef: string;
  questionnaireResponseId: number;
  currentPage: number;
  isLoading: boolean;
  loadingError: any;
  isSaving: boolean;
  savingError: any;
  questionnaire: QuestionnaireDetail | undefined;
  pages: QuestionnairePage[];
  questions: QuestionnaireQuestion[];
  answers: QuestionnaireAnswer[];
  feedbackOptions: FeedbackOption[];
  showPositiveFeedbackOptions: boolean;
  showGroupFeedbackOptions: boolean;
  starRatingSaving: boolean;
  starRatingSaved: boolean;
  coachingSessions?: CoachingSession;
  coachingFeedbackSaving: boolean;
  coachingFeedbackSaved: boolean;
}

export const initialState: State = {
  uniqueRef: '',
  questionnaireResponseId: 0,
  currentPage: -1,
  isLoading: false,
  loadingError: undefined,
  isSaving: false,
  savingError: undefined,
  questions: [],
  answers: [],
  pages: [],
  questionnaire: undefined,
  feedbackOptions: [],
  showPositiveFeedbackOptions: true,
  showGroupFeedbackOptions: false,
  starRatingSaving: false,
  starRatingSaved: false,
  coachingFeedbackSaving: false,
  coachingFeedbackSaved: false
};


export const reducer = createReducer(
  initialState,
  on(questionnaireActions.loadQuestionnaire, (state, props) => ({
    ...state, ...{
      uniqueRef: props.uniqueRef,
      questionnaireResponseId: props.questionnaireResponseId,
      isLoading: true,
      loadingError: undefined
    }
  })),
  on(questionnaireActions.loadQuestionnaireFailure, (state, error) => ({
    ...state, ...{
      isLoading: false,
      loadingError: error.error
    }
  })),
  on(questionnaireActions.loadQuestionnaireSuccess, (state, props) => ({
    ...state, ...{
      isLoading: false,
      loadingError: undefined,
      questionnaire: props.data.questionnaire,
      questions: props.data.questions?.map((q) => {
        let match = props.data.answers.find((x) => x.questionnaireQuestionId == q.id);
        return {
          ...q, ...{
            answerValue: match?.value,
            answerTextValue: match?.textValue
          }
        };
      }),
      answers: props.data.answers,
      pages: props.data.pages
    }
  })),
  on(questionnaireActions.setQuestionnaireAnswer, (state, props) => {
    let mappedAnswers = state.answers;
    if (mappedAnswers.findIndex((x) => x.questionnaireQuestionId == props.questionnaireQuestionId) > -1) {
      //Replace the answer in the array with a new object
      mappedAnswers = mappedAnswers.map((x) => {
        if (x.questionnaireQuestionId == props.questionnaireQuestionId)
          return {
            ...x, ...{
              answerValue: props.value,
              answerTextValue: props.textValue
            }
          };
        return x;
      });
    } else {
      //Append the new answer to the array
      mappedAnswers = [...mappedAnswers, {
        questionnaireQuestionId: props.questionnaireQuestionId,
        value: props.value,
        textValue: props.textValue
      } as QuestionnaireAnswer];
    }

    return {
      ...state, ...{
        questions: state.questions.map((x) => {
          if (x.id == props.questionnaireQuestionId)
            return {
              ...x, ...{
                answerValue: props.value,
                answerTextValue: props.textValue
              }
            };
          return x;
        }),
        answers: mappedAnswers
      }
    };
  }),
  on(questionnaireActions.setQuestionnairePage, (state, props) => ({
    ...state, ...{
      currentPage: props.pageId
    }
  })),
  on(questionnaireActions.saveAnswers, (state, props) => ({
    ...state, ...{
      isSaving: true,
      savingError: undefined
    }
  })),
  on(questionnaireActions.saveAnswersFailure, (state, error) => ({
    ...state, ...{
      isSaving: false,
      savingError: error
    }
  })),
  on(questionnaireActions.saveAnswersSuccess, (state, props) => ({
    ...state, ...{
      isSaving: false,
      savingError: undefined,
    }
  })),
  on(questionnaireActions.LoadQuestionnaireDetails.request, (state) => ({
    ...state,
    isLoading: true,
    loadingError: undefined,
  })),
  on(questionnaireActions.LoadQuestionnaireDetails.success, (state, props) => ({
    ...state,
    isLoading: false,
    loadingError: undefined,
    questionnaire: props.response,
  })),
  on(questionnaireActions.LoadQuestionnaireDetails.failure, (state, error) => ({
    ...state,
    isLoading: false,
    loadingError: error.error,
    questionnaire: undefined,
  })),
  on(questionnaireActions.CreateQuestionnaireResponse.request, (state, props) => ({
    ...state, ...{
      isLoading: true,
      loadingError: undefined
    }
  })),
  on(questionnaireActions.CreateQuestionnaireResponse.success, (state, props) => ({
    ...state, ...{
      isLoading: false,
      loadingError: undefined,
      questionnaire: props.data.questionnaire,
      questionnaireResponseId: props.data.questionnaireResponseId,
      questions: props.data.questions.map((q) => {
        let match = props.data.answers.find((x) => x.questionnaireQuestionId == q.id);
        return {
          ...q, ...{
            answerValue: match?.value,
            answerTextValue: match?.textValue
          }
        };
      }),
      answers: props.data.answers,
      pages: props.data.pages
    }
  })),
  on(questionnaireActions.CreateQuestionnaireResponse.failure, (state, error) => ({
    ...state, ...{
      isLoading: false,
      loadingError: error.error
    }
  })),
  on(questionnaireActions.GetFeedbackOptions.Success, (state, props) => ({
    ...state,
    feedbackOptions: props.options,
  })),
  on(questionnaireActions.SetShowPositiveFeedbackOptions, (state, props) => ({
    ...state,
    showPositiveFeedbackOptions: props.positive
  })),
  on(questionnaireActions.SetShowGroupFeedbackOptions, (state, props) => ({
    ...state,
    showGroupFeedbackOptions: props.isGroup
  })),
  on(questionnaireActions.SaveStarRating.Request, (state) => ({
    ...state,
    starRatingSaving: true,
    starRatingSaved: false
  })),
  on(questionnaireActions.SaveStarRating.Success, (state, props) => ({
    ...state,
    starRatingSaving: false,
    starRatingSaved: true
  })),
  on(questionnaireActions.SaveStarRating.Fail, (state) => ({
    ...state,
    starRatingSaving: false,
    starRatingSaved: true
  })),
  on(questionnaireActions.GetCoachingSessionInfo.Success, (state, props) => ({
    ...state,
    coachingSessions: props.session
  })),
  on(questionnaireActions.SaveCoachingSessionFeedback.Request, (state) => ({
    ...state,
    coachingFeedbackSaving: true,
    coachingFeedbackSaved: false
  })),
  on(questionnaireActions.SaveCoachingSessionFeedback.Success, (state) => ({
    ...state,
    coachingFeedbackSaving: false,
    coachingFeedbackSaved: true
  })),
  on(questionnaireActions.SaveCoachingSessionFeedback.Fail, (state) => ({
    ...state,
    coachingFeedbacksSaving: false,
    coachingFeedbackSaved: true
  })),

);

