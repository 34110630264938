import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SurveyComponent } from './survey/survey.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DragulaModule } from 'ng2-dragula';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faChevronCircleRight,
  faChevronCircleLeft,
  faSpinner,
  faStar as faStarSolid,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { NgxSliderModule } from '@confusedfish/ngx-slider-v2';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireAPIService } from './services/questionnaire-api.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './reducers';
import { QuestionnaireEffects } from './effects/questionnaire.effects';
import { NotFoundComponent } from './not-found/not-found.component';
import { ErrorTextPipe } from './error-text.pipe';
import { SurveyEffects } from './effects/survey.effects';
import { SurveyDemoComponent } from './survey-demo/survey-demo.component';
import { QuestionnaireIntroComponent } from './questionnaire-intro/questionnaire-intro.component';
import { ReplaceNullWithTextPipe } from './replace-null-with-text.pipe';
import { ErrorHandlerService } from './error-handler.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SafePipe } from './safe.pipe';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { faCustArrowDown, faCustArrowUp } from './icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { CoachingSessionFeedbackComponent } from './coaching-session-feedback/coaching-session-feedback.component';
import { BtnActionComponent } from './ui/btn-action/btn-action.component';
import { DropdownSelectComponent } from './ui/dropdown-select/dropdown-select.component';
import { SliderComponent } from './questionnaire/slider/slider.component';
import { LikertComponent } from './questionnaire/likert/likert.component';
import { SummaryComponent } from './summary/summary.component';

@NgModule({
  declarations: [
    AppComponent,
    SurveyComponent,
    SurveyDemoComponent,
    WelcomeComponent,
    SignUpComponent,
    QuestionnaireComponent,
    QuestionnaireIntroComponent,
    NotFoundComponent,
    ErrorTextPipe,
    ReplaceNullWithTextPipe,
    PrivacyPolicyComponent,
    SafePipe,
    StarRatingComponent,
    CoachingSessionFeedbackComponent,
    BtnActionComponent,
    DropdownSelectComponent,
    SliderComponent,
    LikertComponent,
    SummaryComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgxSliderModule,
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    DragulaModule.forRoot(),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forFeature([QuestionnaireEffects, SurveyEffects]),
  ],
  providers: [
    QuestionnaireAPIService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faChevronRight,
      faChevronLeft,
      faChevronCircleRight,
      faChevronCircleLeft,
      faSpinner,
      faStarSolid,
      faCircle
    );

    library.addIcons(faStar);

    library.addIcons(faCustArrowUp, faCustArrowDown);
    config.autoAddCss = false;
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
