import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from '@confusedfish/ngx-slider-v2';
import { QuestionnaireQuestionTypes } from 'src/app/models/ui-models.interface';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @Input() questionType = QuestionnaireQuestionTypes.Strengthometer;

  @Input() leftLabel: string = '';
  @Input() midLabel: string = '';
  @Input() rightLabel: string = '';

  @Input() sliderValue?: number;
  @Output() sliderValueChange = new EventEmitter<number | undefined>();

  @Input() previousValue?: number;

  value = 50;
  sliderOptions: Options = { floor: 0, ceil: 100 };
  questionTypes = QuestionnaireQuestionTypes;

  ngOnInit() {
    this.value = this.sliderValue ?? 50;

    if (this.questionType == this.questionTypes.DoubleStrengthometer) {
      this.sliderOptions.showTicks = true;
      this.sliderOptions.ticksArray = [this.previousValue ?? 50];
    } else if (this.questionType == this.questionTypes.Sweetspot) {
      this.sliderOptions.showTicks = true;
      this.sliderOptions.ticksArray = [40, 60];
      this.sliderOptions.showSelectionBarFromValue = 50;
      /*this.sliderOptions.selectionBarGradient = { from: '#31C99A', to: '#FD8740'}*/
    }
  }

  onValueChange() {
    this.sliderValueChange.emit(this.value);
  }
}
