<div class="modal-header">
  <h1 class="modal-title pull-left">Privacy Policy</h1>
  <ng-container *ngIf="bsModalRef">
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
      <h1 aria-hidden="true">&times;</h1>
    </button>
  </ng-container>
</div>
<div class="modal-body">
  <p>Your privacy is important to us.</p>

  <p>This statement applies to a Mindflick product called ’Spotlight’, our personality profiling tool. We only collect information from you through the Spotlight profiler with your permission, and in accordance with this policy.</p>

  <p>The purpose of this statement is to explain what personal data we collect from you, how we may use this data and how we protect it.</p>

  <p>We encourage you to read this policy.</p>

  <p>Mindflick Holdings Ltd is committed to protecting and respecting your privacy by complying with the General Data Protection Regulation and being registered with the  UK Information Commissioner's Office (registration number ZA364623).</p>


  <h3>'At a Glance' Summary</h3>
  <p>As you complete the Spotlight questionnaire, the responses you give are securely sent to our servers based in the United Kingdom. We then use your responses to generate your unique Spotlight profile. You will receive your profile from an Accredited Spotlight Practitioner, either in a 1-1 setting, or as a part of training event.</p>

  <p>Accredited Spotlight Practitioners are required to never share the contents of your personal Spotlight Profile with anyone else (including your employer) without your explicit permission.</p>

  <p>We will never sell your personal data for any purpose.</p>

  <hr />

  <h2>Here Are Some More Details:</h2>

  <h3>Who are we?</h3>
  <p>We are Mindflick Holdings Ltd (“Mindflick”) and we are registered in England and Wales (registered company number 09655591), with registered offices at Unit F, Whiteacres, Cambridge Road, Whetstone, Leicestershire, LE8 6ZG.</p>

  <p>Mindflick is committed to protecting and respecting your privacy, and to acting in compliance with the General Data Protection Regulation (registration number Z6917638). Mindflick is the “data controller” for all of the information you enter into the Spotlight questionnaire. For more information about Mindflick, please visit <a target="_blank" href="https://www.mindflick.co.uk/about-us/">www.mindflick.co.uk/about-us</a></p>

  <h3>What Data Do We Collect & Process?</h3>

  <p>When you complete the Spotlight questionnaire, Mindflick obtain data from you in order to generate your Spotlight profile - a short report detailing your performance preferences.</p>

  <ul>
    <li>
      <b>Personal Details:</b><br />
      To complete the Spotlight questionnaire, you will be asked for the following “Personal Details”:<br />
      <ul>
        <li>A Spotlight ID, which will be supplied to you</li>
        <li>First Name and Family Name</li>
        <li>Name of your employer / organisation</li>
        <li>Email address</li>
        <li>Gender</li>
      </ul>
    </li>
    <li>
      <b>Preference Data:</b><br />
      Once you have entered your Personal Details, you will be shown a series of adjectives and asked to rate how well these describe you relative to the other adjectives presented. Mindflick then uses this “Preference Data” to generate your Spotlight Profile.
    </li>
    <li>
      <b>IP Address:</b><br />
      Our UK based servers will also record the IP address of the computer you use to complete the Spotlight questionnaire. We only use this information to help us to identify and resolve any technical issues that might occur whilst you are using the Spotlight questionnaire, and to ensure the security and functioning of the website. We will keep a record of your IP address for no more than six months.
    </li>
  </ul>

  <h3>Why Do We Process the Data You Provide?</h3>

  <p>Our lawful basis for processing your personal data is that it is necessary for the purposes of legitimate interests (i.e the production of the Spotlight profile) pursued by the controller or a third party.</p>
  <p>Mindflick uses the Personal Details you provide, together with your Preference Data to generate your Spotlight Profile. We will provide a copy of your Spotlight Profile to you within a workshop or during a 1-1 facilitated by an Accredited Spotlight Practitioner.</p>
  <p>If your employer or organisation requests it, we will use the data to provide them with your overall “Performance Preference” and to generate a “Team Map” showing where the Positions of you and your colleagues sit in relation to each other.</p>

  <h3>Further Spotlight Information:</h3>

  <p>Within a Spotlight profile, we use colours and a shaded spotlight to represent the recipient’s unique blend of preferences. This also conveys how they tend to behave and how they tend to respond when there is something to be won or lost (this will be covered in detail during your workshop or debrief). The combination of colours is also summarised by a Performance Preference.</p>
  <p>A Team Map is a graphic representation of your Performance Preference together with those of your colleagues. Each of the Positions on a team map represents a different combination of colour energies.</p>
  <p>It is important to understand that the purpose of the Spotlight profile is to help an individual learn and develop, encouraging the recipient to find new ways to flex their style in order to connect with others, and find new ways to cope more effectively. A Spotlight Profile in no way measures capability and therefore should never be used for the purposes of selection or recruitment.</p>

  <h3>What Happens After Your Workshop?</h3>

  <p>Following your workshop or 1-1 debrief, Mindflick will keep your data on its secure servers for a maximum of 5 years. At your request, we will delete your personal data from our records.</p>

  <label>
    We may use this data for the following purposes:
  </label>
  <ul>
    <li>To improve our products and services;</li>
    <li>To conduct further research and product testing.</li>
    <li>To let you see how your preferences change over time, i.e. if you were to complete the profile again in the future.</li>
  </ul>

  <p>Should you 'opt-in' and grant us permission to contact you for marketing purposes, we may also use your information to communicate directly with you. For example, informing you about new updates and product information;</p>

  <h3>Who Has Access to Your data?</h3>

  <p>We do not sell, rent or exchange your personal information with any other third party for commercial reasons.</p>
  <p>To receive your Spotlight Profile, you must attend a workshop, 1-1 debrief, or coaching session facilitated by an Accredited Spotlight Practitioner. In order to do this, we will share your Spotlight Profile with the Practitioner before they deliver it to you.</p>
  <label>
    All Accredited Spotlight Practitioners are either:
  </label>
  <ul>
    <li>Mindflick employees</li>
    <li>Independent licensed Practitioners; or are</li>
    <li>Employed by the company or organisation that purchased the profile for you.</li>
  </ul>

  <p>In order to maintain their Spotlight Accreditation, all Accredited Spotlight Practitioners must protect your right to confidentiality, and handle your data in compliance with this policy. They will only share your data with others, including your colleagues and your employer, either (a) where it is permitted by this policy, or (b) if you give your explicit permission.</p>
  <p>During a workshop, you may be invited by the Practitioner to share information from your profile with the group. However, only you will decide whether to share your profile.</p>
  <p>Accredited Spotlight Practitioners may have administrative assistants to help them in preparing and delivering your profile to you. We only permit these individuals to handle your profile for the purpose of assisting the Practitioner in providing a copy of the Spotlight profile directly to you.</p>
  <p>Accredited Spotlight Practitioners may also use external printing suppliers to produce a hard copy of your Spotlight profile for you. Accredited Spotlight Practitioners must check external printing suppliers’ respective privacy policies, confirming that they are GDPR compliant. If such a supplier is used, the Accredited Spotlight Practitioner must also request that your information is deleted after your Spotlight profile has been printed.</p>
  <p>If your employer or organisation requests a Team Map, then we will provide this. However, we will still not share your unique Spotlight Profile without your explicit permission.</p>

  <h3>Where is your data stored and how do we keep it secure?</h3>

  <p>Your data (including your personal data and your responses to the Spotlight questionnaire) is stored on secure servers located in the United Kingdom.</p>
  <p>We take the security of your data and the accessibility to our systems very seriously. The UK based Data Centre's that we use are compliant with the ISO 27018 standard for cloud privacy. With the main Data Centres located in London and Cardiff, data is stored within the boundaries of UK laws. Full testing has been performed to ensure that these act in a failover pair, thus again ensuring data can reside within the boundaries of the UK even during a Data Centre outage.</p>
  <p> By submitting your personal data, you agree to this. We take steps to ensure that the information we collect is processed according to this Privacy Statement and the requirements of applicable law wherever the data is located.</p>

  <h3>What Happens in the Event of a Data Breach?</h3>

  <p>Mindflick have a procedure in place if there is a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed.</p>
  <p>In such cases, we will assess the scope and impact of the breach. Based on this assessment and the likely risks to the rights and freedoms of individuals, we will notify the individuals and/or their connected organisations that a data breach has occurred.</p>
  <p>Any such notification to individuals will be carried out as soon as reasonably possible and will include information on the nature of the breach. Such individuals will also be provided advice on how to make a complaint to the UK Information Commissioner's Office (ICO).</p>
  <p>As Mindflick Holdings Ltd is a UK company we are legally required to register with the UK ICO, an independent authority set up to uphold data privacy. The ICO is internationally recognised as being at the forefront of the protection of personal information. If, due to the nature of a data breach Mindflick is required to inform the ICO, we will do so within 72 hours of becoming aware of the essential facts of the breach.</p>
  <p>It is important to note that Mindflick Holdings Ltd cannot be held responsible for client-side breaches of data confidentiality.</p>

  <h3>What countries could your data could be transferred to?</h3>

  <p>Mindflick provides products and services to customers around the world. However, we store your data on our servers in the UK. We will only transfer your data outside of the European Economic Area (“EEA”) in the following situations:</p>
  <ul>
    <li>You are outside the EEA and we are transferring your data to you; or</li>
    <li>We are transferring your data to a Practitioner who is assigned to deliver your data to you.</li>
  </ul>
  <p>All Accredited Spotlight Practitioners are required to handle the personal data in strict compliance with this policy and with the General Data Protection Regulation.</p>

  <h3>How Long Will We Retain Your Data?</h3>

  <p>We will only keep data for as long as necessary to meet these purposes. At your request, we will delete your personal data from our records.</p>
  <p>Requests for the deletion of personal data should be sent to our Data Protection Officer by email (switch@mindflick.co.uk) or at the postal address at the end of this policy.</p>
  <p>If you have any questions about Mindflick Holdings Ltd's privacy policy, wish to opt out of direct marketing, or wish to request any information about your personal data, please contact us at:</p>
  <p>
    The Data Protection Officer<br />
    Mindflick Holdings Ltd<br />
    3, The Barn<br />
    Hathersage Hall Business Centre<br />
    Main Road<br />
    Hathersage<br />
    Derbyshire<br />
    S32 1BB
  </p>

  <p>E-mail: <a target="_blank" href="mailto:switch@mindflick.co.uk">switch@mindflick.co.uk</a></p>
</div>
<div class="modal-footer" *ngIf="bsModalRef">
  <button type="button" class="btn btn-primary" (click)="hideModal()">Close</button>
</div>
