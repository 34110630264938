import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction, Store } from '@ngrx/store';
import { empty, noop, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { noOpAction, setBackground } from '../actions/layout.actions';
import * as questionnaireActions from '../actions/questionnaire.actions';
import { AppState } from '../reducers';
import { BackgroundStyle } from '../reducers/layout.reducer';
import { QuestionnaireAPIService } from '../services/questionnaire-api.service';



@Injectable()
export class QuestionnaireEffects {
  constructor(
    private actions$: Actions,
    private api: QuestionnaireAPIService,
    private store: Store<AppState>,
    private router: Router
  ) { }

  loadQuestionnaire$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.loadQuestionnaire),
    exhaustMap(action =>
      this.api.getQuestionnaireData(action.uniqueRef, action.questionnaireResponseId).pipe(
        map(data => questionnaireActions.loadQuestionnaireSuccess({ data })),
        catchError(error => of(questionnaireActions.loadQuestionnaireFailure({ error })))
      )
    )
  ));

  loadQuestionnaireDetails$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.LoadQuestionnaireDetails.request),
    exhaustMap(action =>
      this.api.getQuestionnaireDetails(action.questionnaireId, action.spotlightResponseId, action.uniqueRef).pipe(
        map(response => questionnaireActions.LoadQuestionnaireDetails.success({ response })),
        catchError(error => of(questionnaireActions.LoadQuestionnaireDetails.failure({ error })))
      )
    )
  ));

  saveAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.saveAnswers),
    withLatestFrom(this.store),
    exhaustMap(([action, state]) =>
      this.api.setQuestionnaireAnswers(state.questionnaire.uniqueRef, state.questionnaire.questionnaireResponseId, action.answers, action.markComplete).pipe(
        map(() => questionnaireActions.saveAnswersSuccess({ nextPageOnceSaved: action.nextPageOnceSaved })),
        catchError(error => of(questionnaireActions.saveAnswersFailure({ error })))
      )
    )
  ));

  //If the save completed and requested moving onto the next page then lets do that here
  nextPageOnceSaved$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.saveAnswersSuccess),
    filter(x=>x.nextPageOnceSaved),
    withLatestFrom(this.store),
    map(([action, state]) => {
      if (state.questionnaire.currentPage < state.questionnaire.pages.length)
        return questionnaireActions.setQuestionnairePage({ pageId: state.questionnaire.currentPage + 1 });
      return noOpAction();
    }),
    filter((action) => action.type != noOpAction.type)
  ));

  createQuestionnaireResponseRequest$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.CreateQuestionnaireResponse.request),
    exhaustMap(action =>
      this.api.createQuestionnaireResponse(
        action.questionnaireId,
        action.spotlightResponseId,
        action.uniqueRef,
        action.respondentName,
        action.respondentCohort,
        action.respondentEmail).pipe(
          map(data => questionnaireActions.CreateQuestionnaireResponse.success({ data, uniqueRef: action.uniqueRef })),
        catchError(error => of(questionnaireActions.CreateQuestionnaireResponse.failure({ error })))
      ),
    ),
  ));

  createQuestionnaireResponseSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.CreateQuestionnaireResponse.success),
    map((action) => {
      this.store.dispatch(questionnaireActions.setQuestionnairePage({ pageId: 0 }));
      if (action.data.questionnaire.isThreeSixty) {
        this.router.navigate([`/three-sixty/${action.data.questionnaireResponseId}`]);
      } else {
        this.router.navigate([`/feedback/${action.data.questionnaireResponseId}/${action.uniqueRef}`]);
      }
    })
  ), { dispatch: false })

  //Move the current page on one if possible
  nextPage$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.nextQuestionnairePage),
    withLatestFrom(this.store),
    map(([action, state]) => {
      if (state.questionnaire.currentPage < state.questionnaire.pages.length)
        return questionnaireActions.setQuestionnairePage({ pageId: state.questionnaire.currentPage + 1 });
      return noOpAction();
    }),
    filter((action) => action.type != noOpAction.type)
  ));

  changePage$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.setQuestionnairePage),
    withLatestFrom(this.store),
    map(([action, state]) => {

      //The effect happens after the reducer so we can check the page number in either the reducer or the action
      //If we are going to the intro page...
      if (state.questionnaire.currentPage == -1) {
        return setBackground({ style: BackgroundStyle.IntroBoxesBottomRight });
      }
      //If we are going from intro to main question pages then use the light background
      //Effects happen after the reducer, so the page will be after the
      if (state.questionnaire.currentPage == 0) {
        return setBackground({ style: BackgroundStyle.BlurredQuestionnaireDark });
      }
      //If we are going into the last page then use the dark background
      if (state.questionnaire.currentPage == state.questionnaire.pages.length) {
        return setBackground({ style: BackgroundStyle.BlurredQuestionnaireDark });
      }
      return noOpAction();
    }),
    filter((action) => action.type != noOpAction.type)
  ));

  getFeedbackOptions$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.GetFeedbackOptions.Request),
    mergeMap(action =>
      this.api.getFeedbackOptions().pipe(
        map(resp => questionnaireActions.GetFeedbackOptions.Success({ options: resp })),
        catchError(error => of(questionnaireActions.GetFeedbackOptions.Fail({ error })))
      ))
  ));

  saveStarRating$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.SaveStarRating.Request),
    mergeMap(action =>
      this.api.saveStarRating(action.body).pipe(
        map(resp => questionnaireActions.SaveStarRating.Success()),
        catchError(error => of(questionnaireActions.SaveStarRating.Fail({ error })))
      ))
  ));

  getCoachingSessionInfo$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.GetCoachingSessionInfo.Request),
    mergeMap(action =>
      this.api.getCoachingSessionInfo(action.sessionId).pipe(
        map(resp => questionnaireActions.GetCoachingSessionInfo.Success({session: resp})),
        catchError(error => of(questionnaireActions.GetCoachingSessionInfo.Fail({ error })))
      ))
  ));

  saveCoachingSessionFeedback$ = createEffect(() => this.actions$.pipe(
    ofType(questionnaireActions.SaveCoachingSessionFeedback.Request),
    mergeMap(action =>
      this.api.saveCoachingSessionFeedback(action.sessionFeedback).pipe(
        map(resp => questionnaireActions.SaveCoachingSessionFeedback.Success()),
        catchError(error => of(questionnaireActions.SaveCoachingSessionFeedback.Fail({ error })))
      ))
  ));

}
