import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setBackground } from '../actions/layout.actions';
import { AppState } from '../reducers';
import { BackgroundStyle } from '../reducers/layout.reducer';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private store: Store<AppState>
) { }

  ngOnInit(): void {
    this.store.dispatch(setBackground({ style: BackgroundStyle.IntroBoxesBottomRight }));
  }

}
