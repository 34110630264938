import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { CreateQuestionnaireResponse, LoadQuestionnaireDetails } from "../actions/questionnaire.actions";
import { AppState } from "../reducers";
import { selectQuestionnaire } from "../selectors/questionnaire.selectors";

@Component({
  selector: 'app-questionnaire-intro',
  templateUrl: './questionnaire-intro.component.html',
  styleUrls: ['./questionnaire-intro.component.scss']
})
export class QuestionnaireIntroComponent implements OnInit {
  respondentName = '';
  respondentCohort = '';
  respondentEmail = '';
  questionnaireId: number = 0;
  spotlightResponseId: number = 0;
  uniqueRef: string = '';

  questionnaire$ = this.store.select(selectQuestionnaire);
  isOnFeedback = false;

  constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe(params => {
      this.questionnaireId = Number(params.get('questionnaireId')) ?? 0;
      this.spotlightResponseId = Number(params.get('spotlightResponseId')) ?? 0;
      this.uniqueRef = params.get('uniqueRef') ?? '';
      this.isOnFeedback = this.router.url.includes('feedback');

      this.store.dispatch(LoadQuestionnaireDetails.request({
        questionnaireId: this.questionnaireId,
        spotlightResponseId: this.spotlightResponseId,
        uniqueRef: this.uniqueRef
      }));
    })
  }

  letsGo() {
    this.store.dispatch(
      CreateQuestionnaireResponse.request({
        questionnaireId: this.questionnaireId,
        spotlightResponseId: this.spotlightResponseId,
        uniqueRef: this.uniqueRef,
        respondentName: this.respondentName,
        respondentCohort: this.respondentCohort,
        respondentEmail: this.respondentEmail
      }));
  }

  get pageDisplayTitle() {
    return this.isOnFeedback ? 'Feedback' : '360\nFeedback'
  }
}
