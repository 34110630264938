<ng-container *ngIf="questionnaire$ | async as currentQuestionnaire">
  <div *ngIf="currentQuestionnaire" class="row g-0 h-100">
    <div class="col-md-4 bg-primary welcome-container">
      <img class="bg-logo" src="./assets/svg/Mindflick_Monogram_Colour_RGB.svg" />
      <img
        class="bg-pattern"
        src="./assets/svg/nudge-patterns/pages-bg-pattern.svg"
      />
      <img
        class="logo position-relative"
        src="./assets/images/logos/mindflick.svg"
      />
  
      <div class="mt-5 d-flex flex-column greeting position-relative">
        <h1
          class="text-white text-wrap pt-5 px-3 pb-0 mb-0 align-middle w-75 overflow-auto"
        >
          {{ currentQuestionnaire.name }}
        </h1>
      </div>
    </div>
    <div
      class="col-md-8 p-5 survey-page h-100 overflow-auto d-flex flex-column"
      #questionnaireContainer
    >
      <ng-container *ngIf="onIntroPage$ | async">
        <h2 class="mb-15">{{ currentQuestionnaire.introText1 }}</h2>
        <p
          [innerHtml]="currentQuestionnaire.introText2"
          class="body-copy"
        ></p>
        <div
          class="d-flex flex-grow-1 align-content-end justify-content-end flex-wrap"
        >
          <button class="btn btn-primary mt-15" (click)="nextPage()">
            Begin questionnaire
          </button>
        </div>
      </ng-container>
      
      <ng-container *ngIf="!(onIntroPage$ | async)">
        <ng-container *ngIf="currentPage$ | async as currentPage">
          <div>
            <h2>{{ currentPage.headerTitle }}</h2>
            <p class="mb-5 mt-15 body-copy">{{ currentPage.headerText }}</p>
    
            <div
              *ngFor="let question of currentPageQuestions$ | async; index as i"
              class="mb-35"
            >
              <h3 class="mb-15">{{ question.title }}</h3>
              <ng-container [ngSwitch]="question.questionType">
                <app-slider
                  *ngSwitchCase="QuestionType.Strengthometer"
                  [leftLabel]="question.strengthometerNegative!"
                  [midLabel]="question.strengthometerNeutral!"
                  [rightLabel]="question.strengthometerPositive!"
                  [(sliderValue)]="answers[i]"
                ></app-slider>
                <app-likert
                  *ngSwitchCase="QuestionType.CustomLikertScale"
                  [questionId]="question.id"
                  [questionType]="QuestionType.CustomLikertScale"
                  [customLabels]="question.likertOptions ? question.likertOptions.split(',') : []"
                  [(likert)]="answers[i]"
                ></app-likert>
                <input
                  *ngSwitchCase="QuestionType.TextSingleLine"
                  class="form-control"
                  [placeholder]="question.placeholderText"
                  [(ngModel)]="answers[i]"  
                />
                <textarea
                  *ngSwitchCase="QuestionType.TextMultipleLines"
                  class="form-control"
                  rows="3"
                  [placeholder]="question.placeholderText"
                  [(ngModel)]="answers[i]"
                ></textarea>
                <app-likert
                  *ngSwitchCase="QuestionType.LikertScale"
                  [questionType]="QuestionType.LikertScale"
                  [(textLikert)]="answers[i]"
                ></app-likert>
    
                <app-likert
                  *ngSwitchCase="QuestionType.RateOutOf10"
                  [questionType]="QuestionType.RateOutOf10"
                  [(likert)]="answers[i]"
                ></app-likert>
                <app-slider
                  *ngSwitchCase="QuestionType.DoubleStrengthometer"
                  [questionType]="QuestionType.DoubleStrengthometer"
                  [previousValue]="
                    ngxSliderOptions && ngxSliderOptions[i].ticksArray ? ngxSliderOptions[i].ticksArray![0] : 50
                  "
                  [leftLabel]="question.strengthometerNegative!"
                  [midLabel]="question.strengthometerNeutral!"
                  [rightLabel]="question.strengthometerPositive!"
                  [(sliderValue)]="answers[i]"
                ></app-slider>
                <app-slider
                  *ngSwitchCase="QuestionType.Sweetspot"
                  [questionType]="QuestionType.Sweetspot"
                  [(sliderValue)]="answers[i]"
                ></app-slider>
                <ng-container *ngSwitchCase="QuestionType.Email">
                  <input
                  
                  class="form-control"
                  type="email"
                  [(ngModel)]="answers[i]"
                />
                <p
                  *ngIf="
                    requiredError &&
                    !emailControl.valid && emailControl.dirty
                  "
                  class="form-error"
                >
                  Please submit a valid email
                </p>
                </ng-container>
                <app-likert 
                  *ngSwitchCase="QuestionType.YesNo" 
                  [questionId]="question.id" 
                  [questionType]="QuestionType.YesNo"
                  [customLabels]="['No','Yes']"
                  [(textLikert)]="answers[i]">
                </app-likert>
                <ng-container *ngSwitchCase="QuestionType.SingleChoice">
                  <div class="d-flex justify-content-between pt-15">
                    <div *ngFor="let option of question.likertOptionsArray" class="form-check form-check-inline d-flex flex-column" (click)="answers[i] = option">
                      <input class="form-check-input m-0 align-self-center" [name]="'likert-custom' + question.id" type="radio" [value]="option" [(ngModel)]="answers[i]">
                      <label class="form-label text-center small-text mt-1">{{ option }}</label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="QuestionType.MultiChoice">
                <div class="d-flex justify-content-between pt-15">
                  <div *ngFor="let option of question.likertOptionsArray" class="form-check form-check-inline d-flex flex-column" (click)="updateMultiSelectAnswer(i, option)">
                    <input class="form-check-input m-0 align-self-center" [name]="'question_' + question.id +'_check'" type="checkbox" [checked]="multiSelectIsChecked(i, option)">
                    <label class="form-label text-center small-text mt-1">{{ option }}</label>
                  </div>
                </div>
                </ng-container>
                <div *ngSwitchDefault class="alert alert-danger">
                  No template provided for question type: {{ question.questionType }}
                </div>
                
                <p class="form-error">
                  {{
                    answers[i] == undefined &&
                    requiredError
                      ? "Please submit an answer."
                      : ""
                  }}
                </p>
                <hr
                  *ngIf="
                    question.hasPageDivider
                  "
                  class="my-35"
                />
              </ng-container>
            </div>
            <p *ngIf="requiredError" class="form-error">
              {{ requiredError ? "Some answers are invalid" : "" }}
            </p>
            <div class="mt-2 w-100 d-flex">
              <app-btn-action
                [secondary]="true"
                [disabled]="currentPageId == 0"
                [icon]="back"
                [iconDimensions]="35"
                (click)="previousPage()"
              ></app-btn-action>
              <div
                class="px-5 flex-fill d-flex align-items-center justify-content-center"
              >
                <div
                  *ngFor="
                    let thisPage of [].constructor(
                      pageCount$ | async
                    );
                    let i = index
                  "
                  class="mx-2"
                >
                  <fa-icon
                    [icon]="['fas', 'circle']"
                    class="fa-circle"
                    [style.color]="
                      i === currentPageId
                        ? '#FD5F00'
                        : i < currentPageId
                        ? '#7000DD'
                        : '#DADBDA'
                    "
                  ></fa-icon>
                </div>
              </div>
              <app-btn-action
                [icon]="next"
                [iconDimensions]="35"
                (click)="nextPage()"
              ></app-btn-action>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="onLastPage$ | async">
        <h2 class="mb-15">{{ currentQuestionnaire.endPageText1 }}</h2>
        <p
          [innerHtml]="currentQuestionnaire.endPageText2"
          class="body-copy"
        ></p>
      </ng-container>
    </div>
  </div>
</ng-container>

