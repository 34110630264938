import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { BackgroundStyle, HeaderLogo } from '../reducers/layout.reducer';

export const selectTitleBarVisible = (state: AppState) => state.layout.titleBarVisible;
export const selectBackgroundStyle = (state: AppState) => state.layout.backgroundStyle ?? BackgroundStyle.Blank;
export const selectHeaderLogo = (state: AppState) => state.layout.headerLogo ?? HeaderLogo.MindFlick;
export const titleBarVisible = createSelector(
  selectTitleBarVisible,
  (visible) => (visible)
);
export const backgroundStyle = createSelector(
  selectBackgroundStyle,
  (style) => (style)
);
export const headerLogo = createSelector(
  selectHeaderLogo,
  (logo) => (logo)
);
