import { createAction, props } from '@ngrx/store';
import { BackgroundStyle, HeaderLogo } from '../reducers/layout.reducer';

export const noOpAction = createAction(
  '[NoOp]'
);

export const setBackground = createAction(
  '[Layout] Set Background',
  props<{
    style: BackgroundStyle;
  }>()
);

export const setTitleBar = createAction(
  '[Layout] Set Title Bar',
  props<{
    visible: boolean;
  }>()
);

export const setHeaderLogo = createAction(
  '[Layout] Set Header Logo',
  props<{ logo: HeaderLogo }>()
);





