<div class="d-flex flex-column custom-slider" [ngClass]="{'sweetspot-slider': questionType == questionTypes.Sweetspot, 'left': value < 50, 'right': value > 50}" >
  <ngx-slider   [(value)]="value" [options]="sliderOptions" (valueChange)="onValueChange()"></ngx-slider>
  <div *ngIf="questionType != questionTypes.Sweetspot" class="d-flex">
    <p class="col-4 fw-bold small-text">{{leftLabel}}</p>
    <p class="col-4 text-center fw-bold small-text">{{midLabel}}</p>
    <p class="col-4 text-end fw-bold small-text">{{rightLabel}}</p>
  </div>
  <div *ngIf="questionType == questionTypes.Sweetspot" class="d-flex">
    <p class="col-4 text-center fw-bold small-text">Underdone</p>
    <p class="col-4 text-center fw-bold small-text">Sweetspot</p>
    <p class="col-4 text-center fw-bold small-text">Overdone</p>
  </div>
</div>
