<div class="row g-0 h-100" *ngIf="(questionnaire$ | async)">
  <div class="col-md-4 bg-primary welcome-container">
    <img class="bg-logo" src="./assets/svg/Mindflick_Monogram_Colour_RGB.svg" />
    <img class="bg-pattern"
         src="./assets/svg/nudge-patterns/pages-bg-pattern.svg" />
    <img class="logo position-relative"
         src="./assets/images/logos/mindflick.svg" />

    <div class="mt-5 d-flex flex-column greeting position-relative">
      <h1 class="text-white text-break text-wrap pt-5 px-3 pb-0 mb-0 align-middle w-75 overflow-auto">
        {{ pageDisplayTitle }}
      </h1>
    </div>
  </div>
  <div class="col-md-8 p-5 survey-page h-100 overflow-auto d-flex flex-column"
       #questionnaireContainer>


    <h2 class="mb-15">{{ (questionnaire$ | async)?.introText1 }}</h2>
    <p [innerHtml]="(questionnaire$ | async)?.introText2 | safe: 'html'" class="body-copy"></p>
    <div *ngIf="(questionnaire$ | async)?.requiresCohort" class="form-group">
      <label>Cohort *</label>
      <select class="form-control" [(ngModel)]="respondentCohort">
        <option selected value="">Please Select...</option>
        <option value="Exec">ADAPT Exec</option>
        <option value="Leader">ADAPT Leader</option>
        <option value="Manager">ADAPT Manager</option>
      </select>
    </div>
    <div class="form-group">
      <label class="form-label">Name *</label>
      <input class="form-control" type="text" [(ngModel)]="respondentName" />
    </div>
    <div *ngIf="(questionnaire$ | async)?.requiresEmail" class="form-group">
      <label class="form-label">Email *</label>
      <input class="form-control" type="text" [(ngModel)]="respondentEmail" />
    </div>
    <div *ngIf="(questionnaire$ | async)?.requiresCompany" class="form-group">
      <label class="form-label">Company</label>
      <input class="form-control" type="text" [(ngModel)]="respondentCohort" />
    </div>
    <button class="btn btn-purple mt-15" [disabled]="!respondentName" (click)="letsGo()">{{(questionnaire$ | async)?.letsGoButtonText}}</button>
    <p class="body-copy mt-15">*Note – this will not be shared or associated with the feedback you provide, but allows us to track who has responded</p>
  </div>
</div>
