export const languages = [
  { name: "Arabic", value: "ar" },
  { name: 'Danish', value: 'da' },
  { name: 'Dutch', value: 'nl' },
  { name: "English", value: "en" },
  { name: "French", value: "fr" },
  { name: 'German', value: "de" },
  { name: "Hindi", value: "hi" },
  { name: 'Italian', value: 'it' },
  { name: "Japanese", value: "ja" },
  { name: "Polish", value: "pl" },
  { name: "Portuguese", value: "pt" },
  { name: "Spanish", value: "es" },

]
