import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyComponent } from './survey/survey.component'
import { WelcomeComponent } from './welcome/welcome.component';
import { SignUpComponent } from './sign-up/sign-up.component'
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { QuestionnaireIntroComponent } from './questionnaire-intro/questionnaire-intro.component';
import { PrivacyPolicyComponentNoModal } from './privacy-policy/privacy-policy.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { CoachingSessionFeedbackComponent } from './coaching-session-feedback/coaching-session-feedback.component';
import { SummaryComponent } from './summary/summary.component';

const routes: Routes = [
  { path: 'survey/:uniqueRef/:spotlightResponseId/page/:page', component: SurveyComponent, data: { animation: 'QuestionPage' } },
  { path: 'survey/:uniqueRef/:spotlightResponseId', component: SurveyComponent, data: { animation: 'QuestionPage' } },
  { path: 'questionnaire/:uniqueRef/:questionnaireResponseId/page/:page', component: QuestionnaireComponent },
  { path: 'questionnaire/:uniqueRef/:questionnaireResponseId', component: QuestionnaireComponent },
  { path: 'questionnaire/:uniqueRef', component: WelcomeComponent },
  { path: 'questionnaire', component: WelcomeComponent },
  { path: 'feedback/:questionnaireId/:spotlightResponseId/:uniqueRef', component: QuestionnaireIntroComponent },
  { path: 'feedback/:questionnaireResponseId/:uniqueRef', component: QuestionnaireComponent },
  { path: 'session/feedback/:userId/:userAk', component: StarRatingComponent },
  { path: 'session/feedback/group', component: StarRatingComponent },
  { path: 'session/feedback/:sessionId', component: CoachingSessionFeedbackComponent },
  { path: 'three-sixty/:questionnaireId/:spotlightResponseId/:uniqueRef', component: QuestionnaireIntroComponent },
  { path: 'three-sixty/:questionnaireResponseId', component: QuestionnaireComponent },
  { path: 'privacy', component: PrivacyPolicyComponentNoModal },
  { path: 'not-found', component: NotFoundComponent },
  //{ path: 'welcome', component: WelcomeComponent, data: { animation: 'WelcomePage' } },
  { path: 'sign-up/group/:creditPurchaseId/:spotlightGroup', component: SignUpComponent, data: { animation: 'SignUpPage' } },
  { path: 'sign-up/:uniqueRef/:spotlightResponseId', component: SignUpComponent, data: { animation: 'SignUpPage' } },
  { path: 'sign-up/:oldSpotlightUniqueRef', component: SignUpComponent, data: { animation: 'SignUpPage' } },
  { path: 'survey/:uniqueRef/:spotlightResponseId/summary', component: SummaryComponent, data: { animation: 'SignUpPage' } },
  //{ path: 'impact', component: ImpactComponent, data: { animation: 'Impact' } },
  { path: '', component: NotFoundComponent, pathMatch: 'full' },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
