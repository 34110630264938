import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  NgControl,
} from "@angular/forms";
import { faDropdownSVG } from "src/app/icons";
import { SelectValue } from "src/app/models/ui-models.interface";

@Component({
  selector: "app-dropdown-select",
  templateUrl: "./dropdown-select.component.html",
  styleUrls: ["./dropdown-select.component.scss"],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     multi: true,
  //     useExisting: DropdownSelectComponent
  //   }
  // ]
})
export class DropdownSelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  label = "";

  @Input()
  light = false;

  @Input()
  placeholder = "";

  @Input()
  dropUp = false;

  @Input()
  readonly = false;

  @Input()
  hasSearch = true;

  private _options: SelectValue[] = [];
  @Input()
  set options(options: SelectValue[]) {
    this._options = options;
    this.filteredOptions = [...this._options];
  }
  get options(): SelectValue[] {
    return this._options;
  }

  @Output()
  selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  private _selectOptions?: ElementRef;
  @ViewChild("selectOptions")
  set selectOptions(content: ElementRef | undefined) {
    this._selectOptions = content;
  }
  get selectOptions(): ElementRef | undefined {
    return this._selectOptions;
  }

  private _searchInput?: ElementRef;
  @ViewChild("searchInput")
  set searchInput(content: ElementRef | undefined) {
    this._searchInput = content;
    this._searchInput?.nativeElement.focus();
  }
  get searchInput(): ElementRef | undefined {
    return this._searchInput;
  }

  @ViewChild("currentSelection")
  currentSelection?: ElementRef;

  @HostListener("document:click", ["$event"])
  clickout(event: MouseEvent) {
    event.stopPropagation();

    if (event === undefined || event.target === null) return;

    if (this.currentSelection === undefined || this.selectOptions === undefined)
      return;

    if (this.isClickIn(event.target, 0)) return;

    this.showOptions = false;
  }

  @HostListener("document:keydown.arrowdown", ["$event"])
  handleArrowDown(event: KeyboardEvent) {
    if (!this.showOptions) return;

    event.preventDefault();

    if (!this.hoveredOption) {
      this.hoveredOption = this.filteredOptions[0];
      return;
    }

    var hoveredOptionIdx = this.filteredOptions.findIndex(
      (x) => x.value === this.hoveredOption?.value
    );
    if (hoveredOptionIdx + 1 > this.filteredOptions.length) {
      this.hoveredOption = this.filteredOptions[0];
      return;
    }

    this.hoveredOption = this.filteredOptions[hoveredOptionIdx + 1];
  }

  @HostListener("document:keydown.arrowup", ["$event"])
  handleArrowUp(event: KeyboardEvent) {
    if (!this.showOptions) return;

    event.preventDefault();

    if (!this.hoveredOption) {
      this.hoveredOption =
        this.filteredOptions[this.filteredOptions.length - 1];
      return;
    }

    var hoveredOptionIdx = this.filteredOptions.findIndex(
      (x) => x.value === this.hoveredOption?.value
    );
    if (hoveredOptionIdx === 0) {
      this.hoveredOption =
        this.filteredOptions[this.filteredOptions.length - 1];
      return;
    }

    this.hoveredOption = this.filteredOptions[hoveredOptionIdx - 1];
  }

  @HostListener("document:keydown.enter", ["$event"])
  handleEnterPress(event: KeyboardEvent) {
    if (!this.showOptions || !this.hoveredOption) return;

    event.preventDefault();

    this.writeValue(this.hoveredOption.value);
  }

  val = undefined;
  set value(val: any) {
    if (val !== this.val) {
      this.val = val;
      this.onChange(val);
      this.onTouched(val);
      this.selectionChanged.emit(this.val);
    }
  }

  selectedOption?: SelectValue;

  hoveredOption?: SelectValue;

  disabled = false;

  showOptions = false;

  dropdown = faDropdownSVG;

  private _filter?: string;
  set filter(value: string | undefined) {
    this._filter = value;

    if (!value || value === "") this.filteredOptions = [...this.options];
    else
      this.filteredOptions = [
        ...this.options.filter((x) =>
          x.description.toLowerCase().includes(value.toLowerCase())
        ),
      ];
  }
  get filter(): string | undefined {
    return this._filter;
  }

  filteredOptions: SelectValue[] = [];

  get invalid() {
    return this.control ? this.control.invalid : false;
  }

  get touched() {
    return this.control ? this.control.touched : false;
  }

  constructor(private control: NgControl) {
    this.control.valueAccessor = this;
  }

  ngOnInit(): void {}

  onChange: any = () => {};
  onTouched: any = () => {
    if (this.control.touched) {
      this.showOptions = true;
    }
  };

  writeValue(value: any): void {
    this.value = value;
    this.selectedOption = this.options.find((x) => x.value === value);
    this.showOptions = false;
    this.filter = undefined;
    this.hoveredOption = undefined;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  showOptionsOn() {
    if (this.readonly) return;

    this.showOptions = true;
  }

  showOptionsOff() {
    this.showOptions = false;
  }

  toggleShowOptions() {
    if (this.readonly) return;

    this.showOptions = !this.showOptions;
  }

  private isClickIn(target: any, iteration: number): boolean {
    if (iteration > 5) return false;

    // In safari the initial click is registered on the component itself rather than any of it's children so need this check to prevent it instantly closing
    if (
      target.localName !== undefined &&
      target.localName === "app-dropdown-select"
    )
      return true;

    if (
      target.id === "dropdownSelect" ||
      target.id === "dropdownList" ||
      target.id === "dropdownPlaceholder" ||
      target.id === "selectedOption"
    )
      return true;

    if (target.parentNode === null || target.parentNode === undefined)
      return false;

    return this.isClickIn(target.parentNode, iteration + 1);
  }
}
