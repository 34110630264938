import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeedbackOption } from '../models/star-rating.interface';
import { AppState } from '../reducers';
import { questionnaireFeatureKey, State } from '../reducers/questionnaire.reducer';

export const selectLoadingQuestionnaireError = (state: AppState) => state.questionnaire.loadingError;
export const selectIsLoadingQuestionnaire = (state: AppState) => state.questionnaire.isLoading;
export const selectIsSavingQuestionnaire = (state: AppState) => state.questionnaire.isSaving;
export const selectCurrentPageId = (state: AppState) => state.questionnaire.currentPage;
export const selectAllPages = (state: AppState) => state.questionnaire.pages;
export const selectAllQuestions = (state: AppState) => state.questionnaire.questions;
export const selectAllAnswers = (state: AppState) => state.questionnaire.answers;
export const selectQuestionnaire = (state: AppState) => state.questionnaire.questionnaire;

export const selectAllFeedbackOptions = (state: AppState) => state.questionnaire.feedbackOptions;
export const selectShowPositiveFeedbackOptions = (state: AppState) => state.questionnaire.showPositiveFeedbackOptions;
export const selectShowGroupFeedbackOptions = (state: AppState) => state.questionnaire.showGroupFeedbackOptions;

export const selectStarRatingSaving = (state: AppState) => state.questionnaire.starRatingSaving;
export const selectStarRatingSaved = (state: AppState) => state.questionnaire.starRatingSaved;
export const selectQuestionnaireState = (state: AppState) => state.questionnaire;

export const selectFeedbackOptions = createSelector(
  selectAllFeedbackOptions,
  selectShowPositiveFeedbackOptions,
  selectShowGroupFeedbackOptions,
  (options: FeedbackOption[], positive: boolean, isGroup: boolean) => {
    return options.filter(x => x.isPositive == positive && x.isGroupOption == isGroup);
  }
)

export const currentPageId = createSelector(selectCurrentPageId, (p) => (p));
export const currentPage = createSelector(
  selectCurrentPageId,
  selectAllPages,
  (pageId, pages) => {
    return pages.find((page) => page.pageId == pageId);
  }
);
export const onIntroPage = createSelector(
  selectCurrentPageId,
  (pageId) => {
    return pageId < 0;
  }
);

export const onLastQuestionPage = createSelector(
  selectCurrentPageId,
  selectAllPages,
  (pageId, pages) => {
    return pages != null && pageId == pages.length - 1;
  }
);

export const onLastPage = createSelector(
  selectCurrentPageId,
  selectAllPages,
  (pageId, pages) => {
    return pages == null || pageId >= pages.length;
  }
);

export const pageCount = createSelector(
  selectAllPages,
  (pages) => {
    return pages?.length;
  }
);

export const currentPageQuestions = createSelector(
  selectCurrentPageId,
  selectAllQuestions,
  selectAllAnswers,
  (pageId, questions, answers) => {
    return questions?.filter(x => x.pageId == pageId).map(q => {
      let match = answers.find(a => a.questionnaireQuestionId == q.id);
      let previousMatch = (q.relatedQuestionId) ? answers.find(a => a.questionnaireQuestionId == q.relatedQuestionId) : undefined;
      if (match == null && !previousMatch)
        return {
          ...q,
          isTextType: q.questionType === 1 || q.questionType === 2 || q.questionType === 3 || q.questionType === 6 || q.questionType == 8 || q.questionType == 11
        };
      return {
        ...q,
        ...{
          isTextType: q.questionType === 1 || q.questionType === 2 || q.questionType === 3 || q.questionType === 6 || q.questionType == 8 || q.questionType == 11,
          answerValue: match?.value,
          answerTextValue: match?.textValue,
          previousValue: previousMatch?.value,
          previousTextValue: previousMatch?.textValue,
        }
      };
    });
  }
);


export const selectCoachingSessionInfo = createSelector(
  selectQuestionnaireState,
  (questionnaireState: State) => questionnaireState.coachingSessions
);

export const selectCoachingSessionSaving = (state: AppState) => state.questionnaire.coachingFeedbackSaving;

export const selectCoachingSessionSaved = (state: AppState) => state.questionnaire.coachingFeedbackSaved;
