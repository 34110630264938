import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { catchError, exhaustMap, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { noOpAction, setBackground } from '../actions/layout.actions';
import * as surveyActions from '../actions/survey.actions';
import { AppState } from '../reducers';
import { BackgroundStyle } from '../reducers/layout.reducer';
import { selectLanguageCode, survey } from '../selectors/survey.selectors';
import { SurveyService } from '../services/survey.service';

@Injectable()
export class SurveyEffects {

  browserCode: string = '';

  //langCodeTranslate: { [key: string]: string } = {
  //  "English": "en-GB",
  //  "French": "fr-FR",
  //  "Portuguese": "pt-PT",
  //  "Dutch": "nl-NL",
  //  "Danish": "da-DK",
  //  "Italian": "it-IT",
  //  "Spanish": "es-ES",
  //  "German": "de-DE"
  //}

  //shortLangMap: { [key: string]: string } = {
  //  "en": "en-GB",
  //  "fr": "fr-FR",
  //  "pt": "pt-PT",
  //  "nl": "nl-NL",
  //  "da": "da-DK",
  //  "it": "it-IT",
  //  "es": "es-ES",
  //  "de": "de-DE"
  //}

  languageCode$ = this.store.select(selectLanguageCode);
  destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private api: SurveyService,
    private store: Store<AppState>,
    private router: Router,
    private translateService: TranslateService

  ) {
    this.languageCode$.pipe(takeUntil(this.destroyed$)).subscribe(code => {
      if (code)
        this.browserCode = code;
      //this.browserCode = this.shortLangMap[code];
    })

    this.browserCode = this.translateService.getBrowserCultureLang() && this.browserCode === '' ? this.translateService.getBrowserCultureLang()! : 'en-GB';
    this.browserCode = this.browserCode;
    //if (this.shortLangMap[this.browserCode])
    //  this.browserCode = this.shortLangMap[this.browserCode];
  }

  loadSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.LoadSurveyFromInvite.request),
    exhaustMap(action =>
      this.api.getInviteId(action.spotlightResponseId, action.uniqueRef, this.browserCode).pipe(
        map(data => surveyActions.LoadSurveyFromInvite.success({ data })),
        catchError(error => of(surveyActions.LoadSurveyFromInvite.failure({ error })))
      )
    )
  ));

  loadSurveyFromSpotlightId$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.LoadSurveyFromSpotlightGroup.request),
    exhaustMap(action =>
      this.api.getSpotlightId(action.creditPurchaseId, action.spotlightId, this.browserCode).pipe(
        map(data => surveyActions.LoadSurveyFromSpotlightGroup.success({ data })),
        catchError(error => of(surveyActions.LoadSurveyFromSpotlightGroup.failure({ error })))
      )
    )
  ));

  loadSurveyFromSpotlightGroup$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.LoadSurveyFromSpotlightGroup.oldRequest),
    exhaustMap(action =>
      this.api.getSpotlightGroup(action.spotlightGroup, this.browserCode).pipe(
        map(data => surveyActions.LoadSurveyFromSpotlightGroup.success({ data })),
        catchError(error => of(surveyActions.LoadSurveyFromSpotlightGroup.failure({ error })))
      )
    )
  ));

  loadSurveyInProgress$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.LoadSurveyInProgress.request),
    exhaustMap(action =>
      this.api.getInProgress(action.request, this.browserCode).pipe(
        map(data => surveyActions.LoadSurveyInProgress.success({ data })),
        catchError(error => of(surveyActions.LoadSurveyInProgress.failure({ error })))
      )
    )
  ));

  createSurveyRequest$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.CreateSurvey.request),
    exhaustMap(action =>
      this.api.createSurvey(action.request).pipe(
        map(data => surveyActions.CreateSurvey.success({ data })),
        catchError(error => of(surveyActions.CreateSurvey.failure({ error })))
      )
    )
  ));

  createSurveySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.CreateSurvey.success),
    exhaustMap(action => {
      this.router.navigate([`survey/${action.data.uniqueRef}/${action.data.surveyId}`]);
      return of(surveyActions.LoadSurveyFromInvite.request({ uniqueRef: action.data.uniqueRef, spotlightResponseId: action.data.surveyId }));
    })
  ));

  updateSurveyRequest$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.UpdateSurvey.request),
    exhaustMap(action =>
      this.api.updateSurvey(action.uniqueRef, action.spotlightResponseId, action.request).pipe(
        map(data => surveyActions.UpdateSurvey.success({ data })),
        catchError(error => of(surveyActions.UpdateSurvey.failure({ error })))
      )
    )
  ));

  updateSurveySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.UpdateSurvey.success),
    exhaustMap(action => {
      this.router.navigate([`survey/${action.data.uniqueRef}/${action.data.surveyId}`]);
      return of(surveyActions.LoadSurveyFromInvite.request({ uniqueRef: action.data.uniqueRef, spotlightResponseId: action.data.surveyId }));
    })
  ));

  saveSurveyAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.SaveSurveyAnswers.request),
    exhaustMap(action =>
      this.api.saveProgress(action.request).pipe(
        map((response) => surveyActions.SaveSurveyAnswers.success(({ data: response }))),
        catchError(error => of(surveyActions.SaveSurveyAnswers.failure({ error })))
      )
    )
  ));

  saveSurveyAnswersSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.SaveSurveyAnswers.success),
    withLatestFrom(this.store),
    map(([_, state]) => {
      if(state.survey.associatedQuestionnaires.length==0 && state.survey.currentPageIsLastPage)
        this.router.navigate([`survey/${state.survey.uniqueRef}/${state.survey.spotlightResponseId}/summary`]);
      else
        this.router.navigate([`survey/${state.survey.uniqueRef}/${state.survey.spotlightResponseId}/page/${state.survey.currentPage}`]);
    })
  ), { dispatch: false });

  getLinkedQuestionnaires$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.GetLinkedQuestionnaires.request),
    exhaustMap(action =>
      this.api.getLinkedQuestionnaires(action.spotlightResponseId, action.uniqueRef).pipe(
        map((response) => surveyActions.GetLinkedQuestionnaires.success(({ data: response }))),
        catchError(error => of(surveyActions.GetLinkedQuestionnaires.failure({ error })))
      )
    )
  ));

  checkExistingSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.checkExistingSurvey),
    withLatestFrom(this.store),
    map(([action, state]) => {
      if (state.survey.survey?.id == action.spotlightResponseId && state.survey.survey.uniqueRef == action.uniqueRef) {
        return noOpAction();
      }

      this.store.dispatch(surveyActions.setSpotlightResponseId({ spotlightResponseId: action.spotlightResponseId }));
      this.store.dispatch(surveyActions.setUniqueRef({ uniqueRef: action.uniqueRef }));

      return surveyActions.LoadSurveyFromInvite.request({ spotlightResponseId: action.spotlightResponseId, uniqueRef: action.uniqueRef });
    }),
    filter((action) => action.type != noOpAction.type)
  ));

  nextPage$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.nextPage),
    withLatestFrom(this.store),
    map(([_, state]) => {
      if (state.survey.currentPage >= 0) {
        this.router.navigate([`survey/${state.survey.uniqueRef}/${state.survey.spotlightResponseId}/page/${state.survey.currentPage}`]);
      }
    })
  ), { dispatch: false });

  previousPage$ = createEffect(() => this.actions$.pipe(
    ofType(surveyActions.previousPage),
    withLatestFrom(this.store),
    map(([_, state]) => {
      if (state.survey.currentPage >= 0) {
        this.router.navigate([`survey/${state.survey.uniqueRef}/${state.survey.spotlightResponseId}/page/${state.survey.currentPage}`]);
      } else {
        this.router.navigate([`survey/${state.survey.uniqueRef}/${state.survey.spotlightResponseId}`]);
      }
    })
  ), { dispatch: false });

  changePage$ = createEffect(() => this.actions$.pipe(
    ofType(
      surveyActions.setSurveyPage,
      surveyActions.nextPage,
      surveyActions.previousPage,
      surveyActions.SaveSurveyAnswers.success
    ),
    withLatestFrom(this.store),
    map(([action, state]) => {

      //The effect happens after the reducer so we can check the page number in either the reducer or the action
      //If we are going to the intro page...
      if (state.survey.currentPage == -2) {
        return setBackground({ style: BackgroundStyle.IntroBoxesBottomRight });
      }
      //If we are going from intro to main question pages then use the light background
      //Effects happen after the reducer, so the page will be after the
      if (state.survey.currentPage >= -1) {
        return setBackground({ style: BackgroundStyle.BlurredQuestionnaireDark });
      }
      //If we are going into the last page then use the dark background
      if (state.survey.survey && state.survey.currentPage == state.survey.survey.pages.length) {
        return setBackground({ style: BackgroundStyle.BlurredQuestionnaireDark });
      }
      return noOpAction();
    }),
    filter((action) => action.type != noOpAction.type)
  ));
}
