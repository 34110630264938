<button
  class="btn"
  [style.height.px]="iconDimensions"
  [style.width.px]="iconDimensions"
  [class.btn-purple]="!secondary"
  [class.btn-secondary]="secondary && !light"
  [class.btn-secondary-light]="secondary && light"
  [class.btn-action]="true"
  [class.border-0]="noBorder"
  [class.reflection]="isReflection"
  [disabled]="disabled"
  (click)="actionClicked($event)"
>
  <div
    class="d-flex justify-content-center h-100"
    [style.font-size.px]="iconDimensions / 2"
  >
    <fa-icon
      *ngIf="!svgPath"
      class="d-flex align-items-center"
      [class]="{ 'fa-sm': isSmallIcon, 'fa-lg': !isSmallIcon }"
      [class.text-primary]="primary"
      [icon]="icon!"
    ></fa-icon>
    <img *ngIf="svgPath" [src]="svgPath" [width]="imgWidth" />
  </div>
</button>
