import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoachingSessionFeedback } from '../models/coaching-session-feedback.interface';
import { CoachingSession } from '../models/coaching-session.interface';
import { FeedbackOption, SaveStarRatingModel } from '../models/star-rating.interface';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireAPIService {

  constructor(
    private http: HttpClient
  ) { }


  getQuestionnaireData(uniqueRef: string, questionnaireResponseId: number): Observable<GetQuestionnaireResponse> {
    return this.http.get<GetQuestionnaireResponse>("./api/questionnaire/details/" + uniqueRef + "/" + questionnaireResponseId);
  }

  getQuestionnaireDetails(questionnaireId: number, spotlightResponseId: number, uniqueRef: string): Observable<QuestionnaireDetail> {
    return this.http.get<QuestionnaireDetail>(`./api/questionnaire/template/${questionnaireId}/${spotlightResponseId}/${uniqueRef}`);
  }

  setQuestionnaireAnswers(uniqueRef: string, questionnaireResponseId: number, answers: QuestionnaireAnswer[], markComplete: boolean) {
    return this.http.post(
      "./api/questionnaire/save",
      {
        uniqueRef: uniqueRef,
        questionnaireResponseId: questionnaireResponseId,
        answers: answers,
        markComplete: markComplete
      }
    );
  }

  createQuestionnaireResponse(questionnaireId: number, spotlightResponseId: number, uniqueRef: string, respondentName: string, respondentCohort: string, respondentEmail: string) {
    return this.http.post<GetQuestionnaireResponse>(
      "./api/questionnaire/create",
      {
        questionnaireId,
        spotlightResponseId,
        uniqueRef,
        respondentName,
        respondentEmail,
        respondentCohort,
        langId: environment.languageId
      }
    );
  }

  getFeedbackOptions() {
    return this.http.get<FeedbackOption[]>('./api/questionnaire/feedback-options');
  }

  saveStarRating(body: SaveStarRatingModel) {
    return this.http.post('./api/questionnaire/star-rating', body);
  }

  getCoachingSessionInfo(sessionId: number): Observable<CoachingSession> {
    return this.http.get<CoachingSession>(`./api/questionnaire/coaching/${sessionId}`);
  }

  saveCoachingSessionFeedback(feedback: CoachingSessionFeedback) {
    return this.http.post(`./api/questionnaire/coaching/${feedback.id}/feedback`, feedback);
  }
}


export interface GetQuestionnaireResponse {
  questionnaireResponseId: number;
  questionnaire: QuestionnaireDetail;
  pages: QuestionnairePage[];
  questions: QuestionnaireQuestion[];
  answers: QuestionnaireAnswer[];
}

export interface GetSpotlightGroupOwnerResponse {
  id: number;
  firstName: string;
  lastName: string;
}

export interface QuestionnaireDetail {
  id: number;
  name: string;
  introText1: string;
  introText2: string;
  letsGoButtonText: string;
  endPageText1: string;
  endPageText2: string;
  endPageImagePath: string;
  orderById: number;
  active: boolean;
  requiresName: boolean;
  requiresEmail: boolean;
  requiresCohort: boolean;
  requiresCompany: boolean;
  isThreeSixty: boolean;
  isFeedbackQuestionnaire: boolean;
}

export interface QuestionnairePage {
  pageId: number;
  headerTitle: string;
  headerText: string;
  questionsHeading: string;
  endButtonText: string;
}

export interface QuestionnaireQuestion {
  id: number;
  pageId: number;
  orderById: number;
  relatedQuestionId: number | undefined;
  questionType: number;
  title: string;
  active: boolean;

  //These values are populated when the questionnaire is first loaded and then whenever the setAnswer action is called
  //this is to reduce data binding
  isTextType: boolean;
  answerValue: number | undefined;
  answerTextValue: string | undefined;
  previousValue: number | undefined;
  previousTextValue: string | undefined;
  placeholderText?: string;
  strengthometerNegative?: string;
  strengthometerNeutral?: string;
  strengthometerPositive?: string;
  hasPageDivider?: boolean;
  likertOptions?: string;
  required?: boolean;

  // Derived values
  likertOptionsArray: string[];
}

export interface QuestionnaireAnswer {
  questionnaireQuestionId: number;
  value: number | undefined;
  textValue: string | undefined;
}
