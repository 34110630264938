<div class="container-flex mx-4 px-4">
  <div class="row intro">
    <div class="col-12">
      <h1>
        {{'welcome.greet' | translate:{'name':userFirstName} }}
      </h1>
    </div>
    <div class="col-xl-8 col-lg-7 col-sm-12">
      <p>{{'welcome.mindflickIntro' | translate}}</p>
      <p> {{'welcome.spotlightMotivation' | translate}}</p>
      <div class="d-flex flex-column bulleted-list mb-2">
        <div class="d-flex align-items-center list-item">
          <div class="bullet-point"></div>
          <div class="bullet-item">{{'welcome.approx15mins' | translate}}</div>
        </div>
        <div class="d-flex align-items-center list-item">
          <div class="bullet-point"></div>
          <div class="bullet-item">{{'welcome.noRightOrWrong' | translate}}</div>
        </div>
        <div class="d-flex align-items-center list-item">
          <div class="bullet-point"></div>
          <div class="bullet-item">{{'welcome.dontOverthink' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block col-xl-4 col-lg-5 col-sm-12 align-self-center text-center btn" (click)="goToSurveyClicked.emit()">
      <img class="coloured-curve" src="./assets/svg/backgrounds/intro/coloured_curve.svg" />
      <button type="button" class="launch btn btn-orange btn-lg" (click)="goToSurveyClicked.emit()">{{'common.letsBegin' | translate}}</button>
    </div>
    <div class="d-block d-lg-none col-12 align-self-center text-center">
      <button type="button" class="btn btn-orange px-4" (click)="goToSurveyClicked.emit()">{{'common.letsBegin' | translate}}</button>
    </div>
  </div>
</div>
