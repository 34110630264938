import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { setTitleBar } from '../actions/layout.actions';
import { GetFeedbackOptions, SaveStarRating, SetShowGroupFeedbackOptions, SetShowPositiveFeedbackOptions } from '../actions/questionnaire.actions';
import { SaveStarRatingModel } from '../models/star-rating.interface';
import { AppState } from '../reducers';
import { selectFeedbackOptions, selectShowPositiveFeedbackOptions, selectStarRatingSaved, selectStarRatingSaving } from '../selectors/questionnaire.selectors';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  feedbackOptions$ = this.store.select(selectFeedbackOptions);
  isPositive$ = this.store.select(selectShowPositiveFeedbackOptions);

  isSaving$ = this.store.select(selectStarRatingSaving);
  isSaved$ = this.store.select(selectStarRatingSaved);

  tentativeRating = 0;
  rating = 0;
  hovering = false;

  showFeedbackOptions = false;

  selectedFeedback: number[] = [];

  additionalComments = '';

  coachAk: number = 0;
  coachId: string = '';

  isGroup = false;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.store.dispatch(setTitleBar({ visible: false }));
    this.store.dispatch(GetFeedbackOptions.Request());

    this.route.paramMap
      .pipe(take(1))
      .subscribe(params => {
        this.coachAk = Number(params.get('userAk'));
        this.coachId = params.get('userId') ?? '';
      });

    if (window.location.href.includes('group')) {
      this.isGroup = true;
      this.store.dispatch(SetShowGroupFeedbackOptions({ isGroup: true }));
    } else {
      this.isGroup = false;
      this.store.dispatch(SetShowGroupFeedbackOptions({ isGroup: false }));
    }
  }

  onRatingClick(rating: number) {
    // In the event that the user decides to change their rating after already selecting some feedback options
    // Clear down the selection only if the options change
    if ((rating === 5 && this.rating < 5) || (rating < 5 && this.rating === 5)) {
      this.selectedFeedback = [];
    }

    this.rating = rating;
    this.showFeedbackOptions = true;

    if (rating >= 5) {
      this.store.dispatch(SetShowPositiveFeedbackOptions({ positive: true }));
    } else {
      this.store.dispatch(SetShowPositiveFeedbackOptions({ positive: false }));
    }
  }

  onFeedbackClick(id: number) {
    if (this.selectedFeedback.includes(id)) {
      this.selectedFeedback = this.selectedFeedback.filter(x => x != id);
    } else {
      this.selectedFeedback.push(id);
    }
  }

  submitStarRating() {
    const savePayload: SaveStarRatingModel = {
      coachId: this.coachId,
      coachAk: this.coachAk,
      feedbackIds: this.selectedFeedback,
      additionalComments: this.additionalComments,
      starRating: this.rating,
      isGroup: this.isGroup
    }

    this.store.dispatch(SaveStarRating.Request({ body: savePayload }));
  }

}
