import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SurveyService } from '../services/survey.service';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import {
  checkExistingSurvey,
  GetLinkedQuestionnaires,
  LoadSurveyInProgress,
  nextPage,
  previousPage,
  SaveSurveyAnswers,
  setLanguageCode,
  setSurveyPage,
} from '../actions/survey.actions';
import {
  currentPageWordPairs,
  selectSurvey,
  selectCurrentPageId,
  currentPageAnswers,
  onSurveyIntroPage,
  onLastSurveyPage,
  onSurveyDemoPage,
  associatedQuestionnaires,
  selectIsLoading,
  selectIsSaving,
  selectLanguageCode,
  selectSupportedLanguages,
  selectCurrentPageIsLastPage,
} from '../selectors/survey.selectors';
import {
  SaveAnswersRequest,
  SpotlightResponse,
  SurveyInProgressRequest,
  SurveyWordPair,
} from '../models/spotlight-response.interface';
import { delay, filter, take, withLatestFrom } from 'rxjs/operators';
import { setHeaderLogo, setTitleBar } from '../actions/layout.actions';
import { HeaderLogo } from '../reducers/layout.reducer';
import { TranslateService } from '@ngx-translate/core';
import { faBackSVG, faNextSVG } from '../icons';
import { languages } from '../languages';
import { SelectValue } from '../models/ui-models.interface';
import { QuestionAnswer } from '../models/question-answer.interface';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, OnDestroy {
  indexString = 'M54321L';
  stacked: any[] = [];

  report: SpotlightResponse = {
    answers: '',
    spotlightId: 0,
    questionSetName: '',
    emailPermission: true,
    pages: [],
    supportedLanguages: ['en']
  };
  page: any = 0;
  type: any = 0;
  questionNumberStr: string = '01';
  targetLetter: string = 'M';

  pageWordPairs: string[] = [];
  unselectedWordPairs: SurveyWordPair[] = [];
  answers: string | undefined;
  pageAnswers: string = '';
  answersAreValid: boolean = false;
  spotlightResponseId?: number;
  uniqueRef?: string = '';
  showUnselectedRanks: boolean = false;
  ownerFirstName: string = '';

  questionAnswerPair: Array<QuestionAnswer> = [];

  ranks: Array<Rank> = [
    {
      letter: 'M',
      title: 'MOST LIKE ME',
      selected: false,
      arrowDirection: 'up',
      arrowColour: '#379AE7',
      required: true,
    },
    {
      letter: '5',
      title: '5',
      selected: false,
      arrowDirection: 'up',
      arrowColour: '#177ECE',
    },
    {
      letter: '4',
      title: '4',
      selected: false,
      arrowDirection: 'up',
      arrowColour: '#0E80D8',
    },
    {
      letter: '3',
      title: '3',
      selected: false,
      arrowDirection: 'down',
      arrowColour: '#0668B1',
    },
    {
      letter: '2',
      title: '2',
      selected: false,
      arrowDirection: 'down',
      arrowColour: '#075FA2',
    },
    {
      letter: '1',
      title: '1',
      selected: false,
      arrowDirection: 'down',
      arrowColour: '#085C9D',
    },
    {
      letter: 'L',
      title: 'LEAST LIKE ME',
      selected: false,
      required: true,
    },
  ];

  langOptions = languages;
  selectedOption: string = 'en';
  selectedLangCode: string = 'en';

  subs = new Subscription();

  survey$ = this.store.select(selectSurvey);
  currentPage$ = this.store.select(selectCurrentPageId);
  currentPageIsLastPage$ = this.store.select(selectCurrentPageIsLastPage);
  currentWordPairs$ = this.store.select(currentPageWordPairs);
  currentPageAnswers$ = this.store.select(currentPageAnswers);
  onIntroPage$ = this.store.select(onSurveyIntroPage);
  onSurveyDemoPage$ = this.store.select(onSurveyDemoPage);
  onLastSurveyPage$ = this.store.select(onLastSurveyPage);
  associatedQuestionnaires$ = this.store.select(associatedQuestionnaires);
  isLoading$ = this.store.select(selectIsLoading);
  isSaving$ = this.store.select(selectIsSaving);
  languageCode$ = this.store.select(selectLanguageCode);
  supportedLanguages$ = this.store.select(selectSupportedLanguages);

  originalLang?: string;

  requiredWarning?: boolean = false;
  name?: string;

  back = faBackSVG;
  next = faNextSVG;

  langCode$ = this.store.select(selectLanguageCode);

  languages = languages;
  languageOptions: SelectValue[] = languages.map(l => ({
    value: l.value,
    description: l.name
  }));

  language = 'en';

  constructor(
    public surveyService: SurveyService,
    private route: ActivatedRoute,
    private router: Router,
    private dragulaService: DragulaService,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.questionAnswerPair = [];
    this.subs.add(
      this.supportedLanguages$.subscribe((langIds) => {
        if (langIds == undefined || langIds.length == 0)
          return;

        this.languageOptions = this.languages.filter(x => langIds.findIndex(l => l.startsWith(x.value)) > -1).map(l => ({
          value: l.value,
          description: l.name
        }));
      }));

    this.subs.add(
      this.languageCode$.subscribe((langCode) => {
        if (langCode) {
          this.selectedLangCode = langCode;
          this.translateService.use(langCode);
          this.selectedOption = this.langOptions.find(
            (x) => x.value == langCode
          )?.name!;
          if (!this.originalLang) this.originalLang = langCode;
        } else {
          this.translateService.setDefaultLang('en');

          const browserLang = this.translateService.getBrowserLang();
          this.translateService.use(
            browserLang && browserLang.match(/en|fr|pt|nl|da|it|es|de/)
              ? browserLang
              : 'en'
          );

          this.selectedOption =
            this.langOptions.find((x) => x.value == browserLang)?.name !=
              undefined
              ? this.langOptions.find((x) => x.value == browserLang)?.name!
              : 'English - EN';
          if (!this.originalLang) this.originalLang = browserLang;
        }
      })
    );

    this.subs.add(
      this.route.paramMap
        .pipe(
          //Perform the UI layout in a moment, not in the ngOnInit to avoid ExpressionChangedAfterItHasBeenCheckedError exceptions
          delay(0)
        )
        .subscribe((params) => {
          let currentPage =
            params.get('page') == null ? -1 : Number(params.get('page'));

          if (this.uniqueRef == params.get('uniqueRef') ?? '')
            return;

          this.uniqueRef = params.get('uniqueRef') ?? '';
          this.spotlightResponseId =
            Number(params.get('spotlightResponseId')) ?? 0;

          this.store.dispatch(setSurveyPage({ pageId: currentPage }));
          this.store.dispatch(
            checkExistingSurvey({
              spotlightResponseId: this.spotlightResponseId,
              uniqueRef: this.uniqueRef,
            })
          );
          this.surveyService.getSpotlightGroupOwner(this.spotlightResponseId).pipe(take(1))
            .subscribe((result) => {
              this.ownerFirstName = result.firstName;
            });
        })
    ); // Subscription param

    this.subs.add(
      this.route.paramMap
        .pipe(
          delay(0),
          filter((params) => !!params.get('uniqueRef') && !!params.get('spotlightResponseId')),
          take(1)
        )
        .subscribe((params) => {
          var uniqueRef = params.get('uniqueRef')!;
          var spotlightResponseId =
            Number(params.get('spotlightResponseId')!);

          this.store.dispatch(GetLinkedQuestionnaires.request({ spotlightResponseId, uniqueRef }));
        }));

    this.subs.add(
      this.survey$.subscribe((survey) => {
        this.report = survey as SpotlightResponse;
        this.answers = survey?.answers;
        this.name = survey?.firstName;
      })
    );

    this.subs.add(
      this.currentPage$.subscribe((page) => {
        this.page = page;
      })
    );

    this.subs.add(
      this.currentWordPairs$
        .pipe(withLatestFrom(this.currentPageAnswers$))
        .pipe(filter(([pairs, answers]) => !!pairs && !!answers))
        .subscribe(([pairs, answers]) => {
          this.updateQuestionsOnPage(pairs, answers);
        })
    );

    this.subs.add(
      this.route.queryParams.subscribe(params => {
        if (params['type']) {
          this.type = params['type'];
          sessionStorage.setItem('type', this.type);
        }
      })
    );

    //Perform the UI layout in a moment, not in the ngOnInit to avoid ExpressionChangedAfterItHasBeenCheckedError exceptions
    setTimeout(() => {
      if (window.innerHeight < 900) {
        this.store.dispatch(setTitleBar({ visible: false }));
      } else {
        this.store.dispatch(setTitleBar({ visible: true }));
      }
      this.store.dispatch(setHeaderLogo({ logo: HeaderLogo.Spotlight }));
    });

    var lsLanguage = localStorage.getItem("language");

    if (lsLanguage) {
      this.language = lsLanguage;
    }

    this.setupDragula();
  }

  ngOnDestroy(): void {
    //Dispose of the dragula group
    this.dragulaService.destroy('word-pairs');
    //destroy all the subscriptions at once
    if (this.subs != null) {
      this.subs.unsubscribe();
    }
    this.removeAnswerState();
  }

  onLanguageSelectionChanged(languageCode: string) {
    if (!languageCode)
      return;
    this.translateService.use(languageCode);
    this.store.dispatch(setLanguageCode({ languageCode: languageCode }));

    if (this.language != languageCode) {
      this.survey$.pipe(take(1)).subscribe(s => {
        const request: SurveyInProgressRequest = {
          email: s != undefined ? s.email ?? '' : '',
          spotlightId: s != undefined ? s.spotlightId ?? 0 : 0,
          creditPurchaseId: s != undefined ? s.creditPurchaseId ?? 0 : 0
        };

        this.store.dispatch(LoadSurveyInProgress.request({ request }));
        localStorage.setItem("language", languageCode);
        this.language = localStorage.getItem("language") || 'en';
      });
    }
  }

  setupDragula() {
    this.dragulaService.createGroup('word-pairs', {
      moves: function (el, source, handle, sibling) {
        return el?.classList.contains('selectable') ?? false;
      },
      accepts: function (el, target, source, sibling) {
        if (target?.classList.contains('not-target')) return false;
        return target?.classList.contains('rank') ?? false;
      },
      copy: true,
    });

    this.subs.add(
      this.dragulaService
        .drop('word-pairs')
        .subscribe(({ el, target, source }) => {
          let elWordIndex = el.getAttribute('data-word-index');
          if (target === null) {
            //element has been copied so if dropped outside of target need to remove the copy
            // and add back into unselected word pair array if dragged from unselected
            // or add back to ranks if dragged from rank
            if (
              !source.classList.contains('rank') &&
              elWordIndex &&
              parseInt(elWordIndex) < this.unselectedWordPairs.length
            )
              this.unselectedWordPairs[+elWordIndex!].selected = false;
            else {
              let sourceLetter: string =
                source.getAttribute('data-rank-letter') ?? '';
              let sourceIndexOfLetter = this.indexString.indexOf(sourceLetter);
              this.ranks[sourceIndexOfLetter].selected = true;
            }
            el.remove();
          } else {
            let elWordIndex = el.getAttribute('data-word-index');
            let targetLetter: string =
              target.getAttribute('data-rank-letter') ?? '';

            //associate rank with index of wordpair moved into it
            let rankIndexOfLetter = this.indexString.indexOf(targetLetter);
            this.ranks[rankIndexOfLetter].selectedWordPairIndex =
              Number(elWordIndex);
            this.replaceAnswerStringAt(Number(elWordIndex), targetLetter);
            this.answersAreValid = this.checkIfValidAnswers();
            this.ranks[rankIndexOfLetter].selected = true;

            let questionAnswer = {} as QuestionAnswer;
            questionAnswer.answer = this.ranks[rankIndexOfLetter].selectedWordPairIndex ?? 0;
            questionAnswer.letter = this.ranks[rankIndexOfLetter].letter;

            this.questionAnswerPair.forEach((element, index) => {
              if (element.answer === questionAnswer.answer) {
                this.questionAnswerPair.splice(index, 1);
                sessionStorage.setItem('answers', JSON.stringify(this.questionAnswerPair));
              }
            });

            this.questionAnswerPair.push(questionAnswer);
            sessionStorage.setItem('answers', JSON.stringify(this.questionAnswerPair));

            //remove the dragged element as no longer required
            el.remove();
            //if wordpair moved from one rank to another, dissociate source rank from wordpair
            if (source?.classList.contains('rank')) {
              let sourceLetter: string =
                source.getAttribute('data-rank-letter') ?? '';
              let sourceIndexOfLetter = this.indexString.indexOf(sourceLetter);
              this.ranks[sourceIndexOfLetter].selectedWordPairIndex = undefined;
              this.ranks[sourceIndexOfLetter].selected = false;
            }
          }
        })
    );

    this.subs.add(
      this.dragulaService.drag('word-pairs').subscribe(({ el, source }) => {
        this.showUnselectedRanks = false;
        //remove dragged word pair from unselected word pair array
        let elWordIndex = el.getAttribute('data-word-index');

        if (
          elWordIndex &&
          parseInt(elWordIndex) < this.unselectedWordPairs.length
        ) {
          this.unselectedWordPairs[+elWordIndex].selected = true;
        }

        //if word pair dragged from ranksremove dragged word pair from ranks
        if (source?.classList.contains('rank')) {
          let sourceLetter: string =
            source.getAttribute('data-rank-letter') ?? '';
          let sourceIndexOfLetter = this.indexString.indexOf(sourceLetter);
          this.ranks[sourceIndexOfLetter].selected = false;
        }
      })
    );
  }

  showHowItWorks() {
    this.store.dispatch(setSurveyPage({ pageId: -1 }));
    this.router.navigate(['../../'], {
      relativeTo: this.route,
    });
  }

  updateQuestionsOnPage(
    pairs: string[] | undefined,
    answers: string | undefined
  ) {
    if (!pairs || !answers) {
      return;
    }

    this.pageWordPairs = pairs;
    this.unselectedWordPairs = this.pageWordPairs.map((pair, idx) => ({
      index: idx,
      wordPair: pair,
      selected: false,
    }));

    this.pageAnswers = answers;

    if (sessionStorage.getItem('answers')) {
      var sessionAnswers = JSON.parse(sessionStorage.getItem('answers') || '[]');
      sessionAnswers.forEach((element: QuestionAnswer) => {
        const rankIndex = this.indexString.indexOf(sessionAnswers[element.answer]);
        this.ranks[rankIndex].selectedWordPairIndex = element.answer;
        this.ranks[rankIndex].selected = true;
        this.unselectedWordPairs = this.unselectedWordPairs.filter((item) => {
          return item.index !== element.answer;
        });
      });
    }

    this.ranks.forEach((rank) => {
      rank.selected = false;
      rank.selectedWordPairIndex = -1;
      if (rank.selectedElement) {
        rank.selectedElement.remove();
        rank.selectedElement = undefined;
      }
    });

    for (let i = 0; i < 4; i++) {
      if (this.pageAnswers[i] !== '-') {
        const rankIndex = this.indexString.indexOf(this.pageAnswers[i]);
        this.ranks[rankIndex].selectedWordPairIndex = i;
        this.ranks[rankIndex].selected = true;
        this.unselectedWordPairs.shift();
      }
      this.answersAreValid = this.checkIfValidAnswers();
    }

    this.questionNumberStr = (this.page + 1).toString();
    if (this.questionNumberStr.length === 1)
      this.questionNumberStr = '0' + this.questionNumberStr;

    //create stacked effect for progressbar
    this.stacked = [];
    for (let i = 0; i < this.page + 1; i++) {
      this.stacked.push({
        value: 4.9,
        type: 'warning',
      });
      this.stacked.push({
        value: 0.1,
        type: 'white',
      });
    }
  }

  checkIfValidAnswers() {
    if (this.pageAnswers.indexOf('-') !== -1) {
      return false;
    }
    if (this.pageAnswers.indexOf('M') === -1) {
      return false;
    }

    if (this.pageAnswers.indexOf('L') === -1) {
      return false;
    }
    return true;
  }

  previousPage() {
    this.store.dispatch(previousPage());
    this.removeAnswerState();
  }

  nextPage() {
    if (this.page <= -1) {
      this.store.dispatch(nextPage());
      this.removeAnswerState();
      return;
    }

    if (!this.answersAreValid) {
      this.requiredWarning = true;
      return;
    }

    this.requiredWarning = false;

    this.updateAllAnswers();
    this.removeAnswerState();

    const request: SaveAnswersRequest = {
      emailpermission: this.report.emailPermission,
      surveyId: this.report.id,
      uniqueRef: this.uniqueRef,
      answers: this.answers,
      langId: this.selectedLangCode,
    };

    this.store.dispatch(SaveSurveyAnswers.request({ request }));
  }

  navigateToQuestionnaire() {
    this.associatedQuestionnaires$.pipe(take(1)).subscribe((questionnaires) => {
      if (questionnaires?.length) {
        this.router.navigate([
          `questionnaire/${this.uniqueRef}/${questionnaires[0].questionnaireResponseId}/page/0`,
        ]);
      }
    });
  }

  private replaceAnswerStringAt(index: number, replacement: string) {
    if (index >= this.pageAnswers.length) {
      return;
    }

    this.pageAnswers =
      this.pageAnswers.substring(0, index) +
      replacement +
      this.pageAnswers.substring(index + 1);
  }

  private updateAllAnswers() {
    const answerIndex = this.page * 4;

    this.answers = this.answers?.substring(0, answerIndex) + this.pageAnswers + this.answers?.substring(answerIndex + 4);
  }

  private removeAnswerState() {
    this.questionAnswerPair.length = 0;
    sessionStorage.removeItem('answers');
  }
}

export interface Rank {
  letter: string;
  title: string;
  titleOverride?: string;
  selectedWordPairIndex?: number;
  selectedElement?: Element;
  selected: boolean;
  arrowDirection?: string;
  arrowColour?: string;
  required?: boolean;
}
