<div class="h-100">
  <div *ngIf="!(onIntroPage$ | async)" class="row g-0 h-100">
    <div class="col-md-4 bg-primary welcome-container">
      <img
        class="bg-logo"
        src="./assets/svg/Mindflick_Monogram_Colour_RGB.svg"
      />
      <img
        class="bg-pattern"
        src="./assets/svg/nudge-patterns/pages-bg-pattern.svg"
      />
      <img
        class="logo position-relative"
        src="./assets/images/logos/mindflick.svg"
      />

      <div class="mt-5 d-flex flex-column greeting position-relative">
        <h1 class="text-white pt-5 pb-0 mb-0 align-middle">WELCOME</h1>
        <h1 class="text-white" *ngIf="name">
          {{ name.toUpperCase() }}
        </h1>
      </div>
    </div>
    <div class="col-md-8 p-5 survey-page d-flex flex-column">
      <ng-container *ngIf="onSurveyDemoPage$ | async" class="survey-page h-100">
        <app-survey-demo></app-survey-demo>
      </ng-container>
      <ng-container
        *ngIf="
          !(onIntroPage$ | async) &&
          !(onSurveyDemoPage$ | async) &&
          !(onLastSurveyPage$ | async)
        "
        class="survey-page d-flex d-row"
      >
        <div class="row mb-3">
          <div class="col-12 col-md-6">
            <h3>Building my Spotlight profile</h3>
            <h3 class="text-primary cursor-pointer my-2" (click)="showHowItWorks()">
              How it works
            </h3>
            <h2 *ngIf="report && report.pages">
              Question {{ questionNumberStr }}/{{ report.pages.length }}
            </h2>
          </div>
          <div class="col-12 col-md-6">
            <div class="language-container">
              <app-dropdown-select [hasSearch]="false" [options]="languageOptions" [ngModel]="language" [ngModelOptions]="{ standalone: true }" (selectionChanged)="onLanguageSelectionChanged($event)"></app-dropdown-select>
            </div>
            <div class="text-end me-2" *ngIf="this.language!='en'">Changing the language only affects the options in the questionnaire</div>
          </div>
        </div>
        <div class="row g-0 flex-fill">
          <div
            class="question-column col-6 d-flex flex-column justify-content-center"
          >
            <div
              *ngFor="let wordPair of unselectedWordPairs; let i = index"
              [class.warning]="showUnselectedRanks"
              class="w-100 my-1 questions word-pairs user-select-none"
            >
              <div
                class="w-100 questions word-pairs user-select-none text-center body-copy"
                [class.word-pair-hidden]="wordPair.selected"
                dragula="word-pairs"
              >
                <div
                  class="draggable bg-primary text-white selectable not-target body-copy"
                  [attr.data-word-index]="wordPair.index"
                >
                  {{ wordPair.wordPair }}
                </div>
              </div>
            </div>
            <div class="body-copy text-center text-grey pt-3">
              All answers need to be dragged into a ranking
            </div>
          </div>
          <div
            class="answer-column col-6 d-flex flex-column justify-content-center"
          >
            <ng-container *ngFor="let rank of ranks">
              <div
                class="questions align-items-center w-100 my-1 user-select-none text-center rank rank-{{
                  rank.letter
                }}"
                dragula="word-pairs"
                [attr.data-rank-letter]="rank.letter"
                [class.not-target]="rank.selected"
                [class.warning]="
                  showUnselectedRanks &&
                  (rank.letter === 'M' || rank.letter === 'L')
                "
              >
                <div
                  class="rank-content"
                  [attr.data-word-index]="rank.selectedWordPairIndex"
                  [class.selectable]="rank.selected"
                  [class.requiredWarning]="
                    rank.required && !rank.selected && requiredWarning
                  "
                >
                  <div
                    class="w-100 user-select-none text-center rank-draggable body-copy text-white bg-primary"
                    [class.word-pair-hidden]="
                      !(
                        rank.selected &&
                        rank.selectedWordPairIndex !== undefined &&
                        pageWordPairs[rank.selectedWordPairIndex]
                      )
                    "
                  >
                    {{ pageWordPairs[rank.selectedWordPairIndex!] }}
                  </div>
                  <div
                    [class.word-pair-hidden]="
                      rank.selected &&
                      rank.selectedWordPairIndex !== undefined &&
                      pageWordPairs[rank.selectedWordPairIndex]
                    "
                  >
                    <div
                      class="w-100 h-100 d-flex user-select-none body-copy align-items-center justify-content-center"
                    >
                      {{ rank.title }}
                      <div
                        *ngIf="
                          rank.required && !rank.selected && requiredWarning
                        "
                        class="body-copy text-required ps-2"
                      >
                        (required) *
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="body-copy pt-3 text-grey text-center d-flex">
              Please ensure that "Most like me" and "Least like me" are filled
              to proceed
            </div>
          </div>
        </div>
        <div class="mt-2 w-100 d-flex">
          <app-btn-action
            [secondary]="true"
            [icon]="back"
            [iconDimensions]="35"
            (click)="previousPage()"
          >
          </app-btn-action>
          <div
            class="px-5 flex-fill d-flex align-items-center justify-content-between"
          >
            <div *ngFor="let thisPage of [].constructor(20); let i = index">
              <fa-icon
                [icon]="['fas', 'circle']"
                class="fa-circle"
                [style.color]="
                  i === page ? '#FD5F00' : i < page ? '#7000DD' : '#DADBDA'
                "
              ></fa-icon>
            </div>
          </div>
          <app-btn-action *ngIf="(!(currentPageIsLastPage$ | async)) || ((associatedQuestionnaires$ | async)?.length)"
                          [secondary]="true"
                          [icon]="next"
                          [iconDimensions]="35"
                          [disabled]="!answersAreValid || (isSaving$ | async)!"
                          (click)="nextPage()">
          </app-btn-action>
          <button class="btn btn-purple"
                  *ngIf="(currentPageIsLastPage$ | async) && !((associatedQuestionnaires$ | async)?.length)"
                  [disabled]="!answersAreValid || (isSaving$ | async)!"
                  (click)="nextPage()">
              Submit
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="onLastSurveyPage$ | async" class="survey-page h-100">
        <ng-container
          *ngIf="
            (associatedQuestionnaires$ | async)?.length;
            else noQuestionnaires
          "
        >
          <h2 class="mb-3">Building your Spotlight profile</h2>
          <div
            class="body-copy border-bottom-grey pb-2 mb-4"
            [innerHtml]="
              (associatedQuestionnaires$ | async)![0].questionnaire.introText2
                | safe : 'html'
            "
          ></div>
          <button class="btn btn-purple" (click)="navigateToQuestionnaire()">
            {{
              (associatedQuestionnaires$ | async)![0].questionnaire
                .letsGoButtonText
            }}
          </button>
        </ng-container>
        <ng-template #noQuestionnaires>
          <h2 class="mb-3">We loved getting to know you!</h2>
          <div class="body-copy border-bottom-grey pb-2 mb-4">
            Thanks for taking the time to complete Spotlight. {{ownerFirstName}} will receive a copy of your profile and will be in touch soon.
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
