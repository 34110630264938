import { Action, createReducer, on } from '@ngrx/store';
import * as layoutActions from '../actions/layout.actions';


export const layoutFeatureKey = 'layout';

export enum BackgroundStyle {
  Blank,
  IntroBoxesMiddle,
  IntroBoxesBottomRight,
  BlurredQuestionnaireDark,
}

export enum HeaderLogo {
  MindFlick,
  Spotlight,
}

export interface State {
  titleBarVisible: boolean;
  backgroundStyle: BackgroundStyle;
  headerLogo: HeaderLogo;
}

export const initialState: State = {
  titleBarVisible: true,
  backgroundStyle: BackgroundStyle.IntroBoxesBottomRight,
  headerLogo: HeaderLogo.MindFlick,
};


export const reducer = createReducer(
  initialState,
  on(layoutActions.setBackground, (state, props) => ({
    ...state, ...{
      backgroundStyle: props.style
    }
  })),
  on(layoutActions.setTitleBar, (state, props) => ({
    ...state, ...{
      titleBarVisible: props.visible
    }
  })),
  on(layoutActions.setHeaderLogo, (state, props) => ({
    ...state,
    headerLogo: props.logo
  }))
);

