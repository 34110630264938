import { animate, AnimationBuilder, style } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { setTitleBar } from '../actions/layout.actions';
import {
  LoadSurveyInProgress,
  setLanguageCode,
} from '../actions/survey.actions';
import { faCustArrowDown, faCustArrowUp } from '../icons';
import {
  SpotlightResponse,
  SpotlightResponsePage,
  SurveyInProgressRequest,
} from '../models/spotlight-response.interface';
import { AppState } from '../reducers';
import { selectLanguageCode, survey } from '../selectors/survey.selectors';
import { Rank } from '../survey/survey.component';
import { languages } from '../languages';

@Component({
  selector: 'app-survey-demo',
  templateUrl: './survey-demo.component.html',
  styleUrls: [
    './survey-demo.component.scss',
    '../survey/survey.component.scss',
  ],
})
export class SurveyDemoComponent implements OnInit, OnDestroy {
  @Input()
  demoPage: SpotlightResponsePage | undefined;

  @Input() selectedOption: string = 'English - EN';

  nextEnabled = false;

  langOptions = languages;

  stepOneDesc = `For each question, there are four statements.
                 Read each statement carefully and then drag the statement that most describes your character and drop it to the box titled ‘MOST LIKE ME’.
                 Then, choose a statement that least describes your character and drag it into the ‘LEAST LIKE ME’ box. Place the remaining statements in any box in-between based on how accurately they represent you.`;

  survey$ = this.store.select(survey);
  languageCode$ = this.store.select(selectLanguageCode);

  survey?: SpotlightResponse;

  originalLang?: string;

  pageToRouteTo? = 0;

  subs = new Subscription();

  constructor(
    private store: Store<AppState>,
    private animationBuilder: AnimationBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subs.add(
      this.languageCode$.subscribe((langCode) => {
        if (langCode) {
          this.translateService.use(
            this.langOptions.find((x) => x.name == langCode)?.value!
          );
          this.selectedOption = this.langOptions.find(
            (x) => x.name == langCode
          )?.name!;
          if (!this.originalLang)
            this.originalLang = this.langOptions.find(
              (x) => x.name == langCode
            )?.value!;
        } else {
          this.translateService.setDefaultLang('en');

          const browserLang = this.translateService.getBrowserLang();
          this.translateService.use(
            browserLang && browserLang.match(/en|fr|pt|nl|da|it|es|de/)
              ? browserLang
              : 'en'
          );

          this.selectedOption =
            this.langOptions.find((x) => x.value == browserLang)?.name !=
            undefined
              ? this.langOptions.find((x) => x.value == browserLang)?.name!
              : 'English - EN';
          if (!this.originalLang) this.originalLang = browserLang;
        }
      })
    );

    this.subs.add(
      this.survey$.subscribe((survey) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.survey = survey;

        let answerIndex = survey?.answers.indexOf('-');
        this.pageToRouteTo = answerIndex ? answerIndex / 4 : answerIndex;
      })
    );

    if (window.innerHeight < 900) {
      this.store.dispatch(setTitleBar({ visible: false }));
    }
  }

  navigateSurvey() {
    this.router.navigate(['./page/' + this.pageToRouteTo], {
      relativeTo: this.activatedRoute,
    });
  }

  setLanguage() {
    var lang = this.langOptions.find((x) => x.name == this.selectedOption)
      ?.value!;
    this.translateService.use(lang);
    this.store.dispatch(setLanguageCode({ languageCode: this.selectedOption }));
    if (this.originalLang != lang) {
      let request: SurveyInProgressRequest = {
        email: this.survey?.email!,
        spotlightId: this.survey?.spotlightId!,
        creditPurchaseId: this.survey?.creditPurchaseId!
      };

      this.store.dispatch(LoadSurveyInProgress.request({ request }));
      this.originalLang = lang;
    }
  }

  ngOnDestroy(): void {
    if (this.subs != null) {
      this.subs.unsubscribe();
    }
  }
}
